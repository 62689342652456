import {ShapeProps as Props} from '../../types/props';
import {filteredStyled, translate} from '../../utils';

const LimitedContainer = filteredStyled('div', [
  'maxHeight',
  'maxWidth',
  'paddingLeft',
  'paddingTop',
  'paddingRight',
  'position',
  'top',
])((props: Omit<Props, 'children'>) => {
  const {left, paddingLeft, paddingTop, position, top} = translate(props);
  return {
    boxSizing: 'border-box',
    left: left,
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    overflow: 'hidden',
    paddingLeft: paddingLeft,
    paddingTop: paddingTop,
    position: position,
    top: top,
    zIndex: props.isAbove ? 1000 : 0,
  };
});

/**
 * Bounded shape at relative/absolute position.
 */
export const Shape = (allProps: Props) => {
  const {children, ...props} = allProps;
  return <LimitedContainer {...props}>{children}</LimitedContainer>;
};
