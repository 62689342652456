import {Grid} from '@mui/material';

import {ResultProps} from './Result.props';
import {DiffViewer} from './DiffViewer';

export const Result = ({result}: ResultProps) => (
  <Grid container direction="row" justifyContent="center" spacing={1} sx={{marginBottom: '20px', width: '100%'}}>
    <DiffViewer
      output={result.out}
      expected={result.ref}
      titles={{outputTitle: 'Current output', expectedTitle: 'Expected output'}}
    />
  </Grid>
);
