import {useContext} from 'react';

import AppContext from '../../context/AppContext';
import {GenericMenu} from './generic-menu/GenericMenu';
import {ButtonSize as Size, Color, MenuProps, Role} from '../../types';

export const roleBasedProps = (role: undefined | Role, logoutCallback: () => void) => {
  if (role) {
    const staffTabs =
      role === Role.ADMIN
        ? [
            {text: 'Staff', to: '/staff/users'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    const teacherTabs = role === Role.TEACHER ? [{text: 'Staff', to: '/staff/curriculum'}] : [];

    const creator =
      role === Role.CREATOR
        ? [
            {text: 'Staff', to: '/staff/categories'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    const authorTabs =
      role === Role.AUTHOR
        ? [
            {text: 'Staff', to: '/staff/contest-templates'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    const courseAdmin =
      role === Role.COURSE_ADMIN
        ? [
            {text: 'Staff', to: '/staff/contest-templates'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    const authorTeacher =
      role === Role.AUTHOR_TEACHER
        ? [
            {text: 'Staff', to: '/staff/contest-templates'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    return {
      logo: '/contests',
      links: [
        {text: 'Profile', to: '/profile'},
        {text: 'Contests', to: '/contests'},
        ...staffTabs,
        ...teacherTabs,
        ...creator,
        ...authorTabs,
        ...courseAdmin,
        ...authorTeacher,
      ],
      buttons: [
        {
          color: Color.TRANSPARENT,
          variant: 'outlined',
          onClick: logoutCallback,
          text: 'Log out',
          size: Size.MEDIUM,
          style: {
            height: '50px',
            width: '25px',
          },
          sx: {
            boxShadow: 0,
            '&:hover': {
              boxShadow: 0,
            },
          },
        },
      ],
    };
  } else {
    return {
      logo: '/',
      links: [
        //{ text: "About", to: "/about" },
        //{ text: "Discover", to: "/discover" },
        {text: 'Contact', to: '/contact'},
      ],
      buttons: [
        {
          color: Color.SECONDARY,
          link: '/login',
          text: 'Log in',
          size: Size.MEDIUM,
        },
        {
          color: Color.PRIMARY,
          link: '/register',
          text: 'Register',
          size: Size.MEDIUM,
        },
      ],
    };
  }
};

/**
 * Horizontal menu with custom options based on the authentication status of the user.
 */
export const Menu = (props: MenuProps) => {
  const context = useContext(AppContext);

  return (
    <>
      <GenericMenu width={props.width} {...roleBasedProps(props.role, context.logoutCallback)} />
    </>
  );
};
