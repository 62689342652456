/* eslint-disable react/prop-types */
import {EllipseProps as Props} from '../../types';
import {rotate, round, filteredStyled} from '../../utils';
import {Shape} from '../shape/Shape';

const Container = filteredStyled('div', ['backgroundColor', 'rotationAngle'])((
  props: Omit<Props, 'bounds' | 'children' | 'parentwidth' | 'scaleToParentWidth'>
) => {
  // Compute the border radius for each corner
  const {bottomLeft, bottomRight, topLeft, topRight} = round(props.size, props.radius ?? props.height ?? 0);
  return {
    backgroundColor: props.backgroundColor,
    borderTopLeftRadius: topLeft,
    borderTopRightRadius: topRight,
    borderBottomLeftRadius: bottomLeft,
    borderBottomRightRadius: bottomRight,
    boxSizing: 'border-box',
    height: props.height ?? 'auto',
    mozBoxSizing: 'border-box',
    transform: `rotate(${props.rotationAngle}deg)`,
    webkitBoxSizing: 'border-box',
    width: props.width,
  };
});

/**
 * Generic ellipse
 */
export const Ellipse = (allProps: Props) => {
  const {backgroundColor, children, height, parentwidth, fitParentWidth, width, ...props} = allProps;

  // Update the width if the ellipse should scale to the parent
  const finalWidth = fitParentWidth ? Math.min(parentwidth!, width) : width;

  // Compute the size of the bounding container
  const size = rotate(finalWidth, height, props.rotationAngle);

  return (
    <Shape {...props.bounds} className={props.className} height={size.height} width={size.width}>
      <Container
        {...props}
        backgroundColor={backgroundColor}
        height={size.height}
        width={size.width}
        sx={{overflow: props.overflow ?? 'visible'}}
      >
        {children}
      </Container>
    </Shape>
  );
};
