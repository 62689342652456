import clsx from 'clsx';
import {Editor} from '@monaco-editor/react';

type CodeEditorProps = {
  title?: string;
  className?: string;
  code: string;
  onChange?: (newValue: string | undefined) => void;
  height?: number;
  readOnly?: boolean;
  enableMinimap?: boolean;
  language?: string;
};

export const CodeEditor = ({
  title,
  className,
  code,
  onChange,
  height,
  readOnly = false,
  enableMinimap = false,
  language = 'java',
}: CodeEditorProps) => (
  <>
    {title && (
      <div className="bg-[#161616]">
        <div className="ml-7 py-2">{title}</div>
      </div>
    )}
    <Editor
      className={clsx(className)}
      language={language}
      theme="vs-dark"
      onChange={(value, _) => {
        onChange && onChange(value);
      }}
      value={code}
      height={height}
      options={{
        readOnly,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        minimap: {
          enabled: enableMinimap,
        },
        scrollbar: {
          alwaysConsumeMouseWheel: false,
        },
      }}
    />
  </>
);
