import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Typography, Grid as MuiGrid} from '@mui/material';
import {CartesianGrid, XAxis, YAxis, Tooltip, Bar, BarChart, Cell, PieChart, Pie} from 'recharts';

import {sendGetRequest} from '../../api';
import {ExportData} from './ExportData';

export const Statistics = () => {
  const colors = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#FF02A1',
    '#A5921F',
    '#FFBB28',
    '#FF02A1',
    '#AB8FF1',
    '#257831',
  ];

  const {id, groupContest} = useParams();
  const [intervalValues, setIntervalValues] = useState([]);
  const [avgGrade, setAvgGrade] = useState([]);

  useEffect(() => {
    sendGetRequest(`/contests/${id}/get_interval_grades?groupContestId=${groupContest}`).then((response) => {
      setIntervalValues(response.data);
    });

    sendGetRequest(`/contests/${id}/get_avg_problem_grade?groupContestId=${groupContest}`).then((response) => {
      setAvgGrade(response.data);
    });
  }, []);

  return (
    <div className="grid grid-cols-12 justify-center items-center rounded-2xl bg-card-main">
      <div className="col-span-6">
        <div className="flex flex-col items-center">
          <PieChart width={600} height={300}>
            <Pie
              dataKey="grade"
              isAnimationActive={false}
              data={intervalValues}
              outerRadius={120}
              label={({cx, cy, midAngle, innerRadius, outerRadius, name}) => {
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
                const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

                return (
                  <text
                    x={x}
                    y={y}
                    fill="#F2F2F2"
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                  >{`${name}`}</text>
                );
              }}
            >
              {intervalValues.map((entry, index) => (
                <Cell key={`pie-cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip
              content={({active, payload}) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="bg-card-light px-3 py-3 rounded-xl">
                      <p>{`Grade: ${payload[0].name}`}</p>
                      <p>{`Count: ${payload[0].value}`}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
          </PieChart>
          <Typography align="center" sx={{width: 250}}>
            Grades distribution chart
          </Typography>
        </div>
      </div>
      <div className="col-span-6">
        <div className="flex flex-col items-center">
          <BarChart width={600} height={300} data={avgGrade}>
            <CartesianGrid stroke="#ffffff" strokeDasharray="5 5" />
            <XAxis dataKey="name" stroke="#ffffff" />
            <YAxis stroke="#ffffff" domain={['auto', 'auto']} />
            <Tooltip labelStyle={{color: '#000000'}} />
            <Bar dataKey="value" barSize={30} fill={'#AB8ECC'}>
              {avgGrade.map((entry, index) => (
                <Cell key={`bar-cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Bar>
          </BarChart>
          <Typography align="center" sx={{width: 250}}>
            Average grade per problem
          </Typography>
        </div>
      </div>
      <div className="col-span-12">
        <div className="flex justify-center">
          <MuiGrid item xs="auto" zeroMinWidth>
            <ExportData />
          </MuiGrid>
        </div>
      </div>
    </div>
  );
};
