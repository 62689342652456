import {useEffect, useState} from 'react';

const getValue = (key: string) => localStorage.getItem(key);

export function useStorage(key: string) {
  const [value, setValue] = useState(getValue(key));

  useEffect(() => {
    const updateValue = () => setValue(getValue(key));
    window.addEventListener('storage', updateValue);
    return () => window.removeEventListener('storage', updateValue);
  }, [key]);

  return value;
}
