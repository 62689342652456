import {useEffect, useState, useContext} from 'react';
import Select from 'react-select';
import {useOutletContext} from 'react-router-dom';
import ActivityCalendar, {Activity} from 'react-activity-calendar';

import makeAnimated from 'react-select/animated';
import {Tooltip, Typography} from '@mui/material';
import {LibraryBooks, Grading} from '@mui/icons-material';

import {URLs, sendGetRequest} from '../../api';
import AppContext from '../../context/AppContext';
import {dropdownStylesConfig} from '../../components/form/dropdown/Dropdown';
import {Group} from '../../types/entities/Group';
import {SubjectCoveredResponse} from '../../api/responses/SubjectCoverageTypes';
import {SuccessRateResponse} from '../../api/responses/SuccessRateTypes';

type ProfileHomeOutletProps = {
  handleHttpError: any;
};

function ProfileHome() {
  const dropdownStyles = dropdownStylesConfig();
  const animatedComponents = makeAnimated();

  const {user} = useContext(AppContext);
  const {handleHttpError}: ProfileHomeOutletProps = useOutletContext();

  const [subjectCovered, setSubjectCovered] = useState<SubjectCoveredResponse>();
  const [successRate, setSuccessRate] = useState<SuccessRateResponse>();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [groups, setGroups] = useState<{value: number; label: string}[]>([]);

  const [submissionsActivity, setSubmissionsActivity] = useState<Array<Activity>>();

  useEffect(() => {
    sendGetRequest(URLs.accessibleUserGroups)
      .then((response) => {
        const responseGroups: Group[] = response.data;
        if (responseGroups.length !== 0) {
          setGroups(responseGroups.map((group) => ({value: group.id, label: group.name})));
          setSelectedGroupId(responseGroups[0].id ?? null);
        }
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, []);

  useEffect(() => {
    if (selectedGroupId) {
      sendGetRequest(URLs.getSubjectCoverage(selectedGroupId))
        .then((response) => {
          setSubjectCovered(response.data);
        })
        .catch((err) => {
          handleHttpError(err);
        });

      sendGetRequest(URLs.getSuccessRate(selectedGroupId, user!.id))
        .then((response) => {
          setSuccessRate(response.data);
        })
        .catch((err) => {
          handleHttpError(err.response.data.message);
        });
      sendGetRequest(URLs.getSubmissionsActivity(selectedGroupId, user!.id))
        .then((response) => {
          setSubmissionsActivity(response.data);
        })
        .catch((err) => {
          handleHttpError(err.response.data.message);
        });
    }
  }, [selectedGroupId]);

  const userDataCard = (
    <div className="flex items-start flex-col px-8 py-6">
      <Typography variant="h2">{`${user?.lastName} ${user?.firstName}`}</Typography>
      <Typography variant="h6">{user?.email}</Typography>
      <Typography variant="h6">{`${user?.role.toUpperCase()}`}</Typography>
    </div>
  );

  const coverageCard = (
    <Tooltip title="How much of the curriculum was covered.">
      <div className="flex flex-col items-center justify-center px-12 py-10">
        <Typography align="center" variant="h6">
          Subject covered:
        </Typography>
        <div className="flex items-center justify-center">
          <LibraryBooks fontSize="large" />
          <Typography align="center" variant="h6">
            {subjectCovered && subjectCovered.noContests !== 0
              ? ((subjectCovered.assignedContests / subjectCovered.noContests) * 100).toFixed(2)
              : '0.00'}
            %
          </Typography>
        </div>
      </div>
    </Tooltip>
  );

  const successRateCard = (
    <Tooltip title="How many problems you tried to solve and succeeded.">
      <div className="flex flex-col items-center justify-center px-12 py-10">
        <Typography align="center" variant="h6">
          Success rate:
        </Typography>
        <div className="flex items-center justify-center">
          <Grading fontSize="large" />
          <Typography align="center" variant="h6">
            {successRate && successRate.totalTriedProblems !== 0
              ? ((successRate.solvedProblems / successRate.totalTriedProblems) * 100).toFixed(2)
              : '0.00'}
            %
          </Typography>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2 rounded-2xl bg-card-dark">{userDataCard}</div>
      <div className="col-span-2 flex flex-row justify-between items-center">
        <Typography variant="h4">User stats</Typography>
        <div className="w-60">
          <Select
            components={animatedComponents}
            value={groups.find((group) => group.value === selectedGroupId)}
            options={groups}
            onChange={(option: unknown) => {
              setSelectedGroupId((option as {value: number; label: string}).value);
            }}
            styles={dropdownStyles}
          />
        </div>
      </div>
      <div className="col-span-1 rounded-2xl bg-card-dark">{successRateCard}</div>
      <div className="col-span-1 rounded-2xl bg-card-dark">{coverageCard}</div>
      <div className="flex col-span-2 rounded-2xl bg-card-dark items-center justify-center px-6 py-4">
        <ActivityCalendar
          renderBlock={(block, activity) => (
            <Tooltip title={`${activity.count} submissions on ${activity.date}`}>{block}</Tooltip>
          )}
          data={submissionsActivity ?? []}
          theme={{dark: ['#1e242e', '#4e5264', '#646e93', '#798bc5', '#8ea9fa']}}
          colorScheme="dark"
          blockSize={18}
          loading={!submissionsActivity}
        />
      </div>
    </div>
  );
}

export default ProfileHome;
