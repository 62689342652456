import {Rule, RuleProps} from './Rule';

type Custom = {
  type: 'custom';
  regex: RegExp;
};
type Email = {
  type: 'email';
};
type NoWhiteSpaces = {
  type: 'noWhiteSpaces';
};
type PhoneNumber = {
  type: 'phoneNumber';
};
type NickName = {
  type: 'nickName';
};

export type PatternProps = RuleProps & (Custom | Email | NoWhiteSpaces | PhoneNumber | NickName);

export class Pattern extends Rule {
  static emailRegex = /^(\w+[_+.-]?)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  static phoneNumberRegex = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/;
  static nickNameRegex = /^[a-zA-Z0-9\s]([._-](?![._-])|[a-zA-Z0-9\s]){6,30}[a-zA-Z0-9\s]$/;

  public validate(value: string, props: PatternProps): false | string {
    if (!value) {
      return this.ifError(false, props.message);
    }
    switch (props.type) {
      case 'custom':
        return this.ifError(props.regex.test(value), props.message);
      case 'email':
        return this.ifError(Pattern.emailRegex.test(value), props.message);
      case 'noWhiteSpaces':
        return this.ifError(value.indexOf(' ') < 0, props.message);
      case 'phoneNumber':
        return this.ifError(Pattern.phoneNumberRegex.test(value), props.message);
      case 'nickName':
        return this.ifError(Pattern.nickNameRegex.test(value), props.message);
      default:
        return false;
    }
  }
}
