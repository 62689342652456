export enum Role {
  STUDENT = 'student',
  TEACHER = 'teacher',
  ADMIN = 'admin',
  CREATOR = 'creator',
  GUEST = 'guest',
  AUTHOR = 'author',
  COURSE_ADMIN = 'course-admin',
  AUTHOR_TEACHER = 'author-teacher',
}

export enum RoleCode {
  STUDENT = 1,
  TEACHER = 2,
  ADMIN = 3,
  CREATOR = 4,
  GUEST = 5,
  AUTHOR = 6,
  COURSE_ADMIN = 7,
  AUTHOR_TEACHER = 8,
}
