import {Button as MuiButton} from '@mui/material';
import {styled} from '@mui/material/styles';

import {ButtonProps as Props} from '../../types';

const CustomButton = styled(MuiButton)((props) => ({
  fontWeight: 'bold',
  width: props.fullWidth ? '100%' : 'fit-content',
  '&.Mui-disabled': {
    color: '#b8b8b8',
  },
}));

/**
 * Custom button
 */
export const Button = ({color, size, label, link, type, variant, disabled, sx, ...props}: Props) => (
  <CustomButton
    color={color}
    href={link}
    size={size}
    type={type ?? 'button'}
    variant={variant ?? 'contained'}
    disabled={disabled}
    sx={sx}
    {...props}
  >
    {label}
  </CustomButton>
);
