import {Grid} from '@mui/material';

import {ButtonProps as Props} from './Button.props';
import {Button as BasicButton, ModalWithButton as Modal} from '../..';
import {ButtonSize as Size, Color} from '../../../types';

export const Button = ({option, show, setShow}: Props) => {
  const button = (
    <BasicButton color={Color.GRID_PRIMARY} label={option.label} onClick={option.onClick} size={Size.LARGE} />
  );
  if (!option.modal) {
    return <Grid item>{button}</Grid>;
  }
  const modal = (
    <Modal
      button={{element: button}}
      children={option.modal.children}
      closeModal={() => setShow(false)}
      height={option.modal.height}
      open={show}
      openModal={() => setShow(true)}
      overflow="scroll"
      width={option.modal.width}
    />
  );
  return <Grid item>{modal}</Grid>;
};
