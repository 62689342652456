export const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        '&.MuiButton-outlined': {
          backgroundColor: 'white',
        },
        '&.MuiButton-contained': {
          '&:disabled': {
            color: 'white',
            // backgroundColor: '#15803e',
            opacity: '90%',
          },
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: 'white',
        '&.Mui-checked': {
          color: 'white',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 6,
        marginLeft: '1px',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        borderColor: 'white',
        color: 'white',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&[id$="-error"]': {
          color: '#CD5C5C',
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        list: {
          backgroundColor: 'black !important',
          color: 'black !important',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: '#cccccc',
        color: 'black !important',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: '#384D58',
        borderColor: 'white',
        color: 'white',
        fontWeight: 500,

        // On click
        '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#666666',
        },

        // On error
        '&.Mui-error': {
          backgroundColor: '#573D3D',
          color: '#FF7070',
        },
        '&.Mui-error svg.MuiSvgIcon-root': {
          color: '#CD5C5C',
        },
        '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#CD5C5C',
          borderWidth: '0 0 2.5px 0',
          color: '#CD5C5C',
        },
        '& .MuiInputAdornment-root': {
          color: 'white',
        },
        '& .MuiSvgIcon-root': {
          color: 'white',
        },
        '& :-webkit-autofill': {
          '-webkit-text-fill-color': 'white',
          '-webkit-box-shadow': '0 0 0 200px #384D58 inset',
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: '#384D58',
        '& :-webkit-autofill': {
          '-webkit-text-fill-color': 'white',
          '-webkit-box-shadow': '0 0 0 200px #384D58 inset',
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: 'white',
        '&.Mui-checked': {
          color: 'white',
        },
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: '#3B465F !important',
        borderRadius: '20px',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: '#424E6A',
        },
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        background: '#628a2c',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          color: 'white',
        },
      },
    },
  },
};
