import {Error, Done, Close} from '@mui/icons-material';
import {Tooltip} from '@mui/material';

type SubmissionStatusProps = {
  compiled: boolean;
  maxGrade: boolean;
};
const SubmissionStatus = ({compiled, maxGrade}: SubmissionStatusProps) => {
  const [message, icon] = compiled
    ? maxGrade
      ? ['Completed', <Done color="secondary" />]
      : ['Wrong answer', <Close color="error" />]
    : ['Compile error', <Error color="warning" />];
  return <Tooltip title={message}>{icon}</Tooltip>;
};
export default SubmissionStatus;
