import {Link} from 'react-router-dom';

import {Typography} from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import {Page} from '../../components';

export const NotFound = () => (
  <Page>
    <div className="flex flex-col items-center py-1 w-full text-secondary-light">
      <SentimentVeryDissatisfiedIcon />
      <Typography variant="h6">Looks like you're trying to access a non-existing resource.</Typography>
      <Typography variant="h6">
        Please
        <Link className="text-secondary-dark" to="/login">
          {' login '}
        </Link>
        and try again.
      </Typography>
    </div>
  </Page>
);
