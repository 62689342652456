export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum EllipseSize {
  FULL = 'full',
  TOP_HALF = 'top-half',
  TOP_LEFT_QUARTER = 'top-left-quarter',
  TOP_RIGHT_QUARTER = 'top-right-quarter',
  BOTTOM_HALF = 'bottom-half',
  BOTTOM_LEFT_QUARTER = 'bottom-left-quarter',
  BOTTOM_RIGHT_QUARTER = 'bottom-right-quarter',
  LEFT_HALF = 'left-half',
  RIGHT_HALF = 'right-half',
}
