import {useOutletContext} from 'react-router-dom';
import {useState, useContext, useEffect} from 'react';

import {Typography, Snackbar} from '@mui/material';
import {ContactPhone, Badge, PermIdentity} from '@mui/icons-material';

import {URLs} from '../../api';
import AppContext from '../../context/AppContext';
import {sendPatchRequest} from '../../api/API';
import {User} from '../../types';
import {Form} from '../../components';
import {updateProperty} from '../../utils';
import {UpdateAccountRequest} from '../../api/Requests';

type ProfileAccountOutletProps = {
  handleHttpError: any;
};

function ProfileAccount() {
  const {handleHttpError}: ProfileAccountOutletProps = useOutletContext();

  const [errors, setErrors] = useState<Record<string, any>>({});
  const [phoneNumberMissing, setPhoneNumberMissing] = useState(false);
  const context = useContext(AppContext);
  const user = context.user!;

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSubmit = (data: UpdateAccountRequest) => {
    sendPatchRequest(`${URLs.updateUser}/${user.id}`, data)
      .then((response) => {
        if (response.data) {
          context.updateUser(
            new User({
              ...user,
              nickName: data.nickName,
              phoneNumber: data.phoneNumber,
              firstName: data.firstName,
              lastName: data.lastName,
            })
          );
          setSnackbarOpen(true);
        } else {
          // TODO: check errors on backend
          setErrors(updateProperty(errors, 'password', 'Introdu parola pentru confirmare'));
        }
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  useEffect(() => {
    if (!user.phoneNumber || user.phoneNumber === 'NULL') {
      setPhoneNumberMissing(true);
    }
  }, [user.phoneNumber]);

  return (
    <div className="items-center">
      <Typography className="mt-25 mb-25" variant="h3">
        Edit your profile
      </Typography>
      {phoneNumberMissing && (
        <Typography className="mt-25 mb-25" variant="h6" color="#CD5C5C">
          The phone number is not set, please update your profile information!
        </Typography>
      )}
      <Form
        button={{
          className: 'mt-25',
          label: 'Update',
          onClick: handleSubmit,
        }}
        fullWidth={true}
        inputs={[
          [
            {
              id: 'firstName',
              error: errors['firstName'],
              placeholder: 'First name',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You need to set your first name',
                  },
                ],
              },
              startAdornment: <PermIdentity />,
              type: 'input',
              value: user.firstName,
            },
            {
              id: 'lastName',
              error: errors['lastName'],
              placeholder: 'Last name',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You need to set your last name',
                  },
                ],
              },
              startAdornment: <PermIdentity />,
              type: 'input',
              value: user.lastName,
            },
          ],
          [
            {
              id: 'nickName',
              error: errors['nickName'],
              placeholder: 'Nickname',
              rules: {
                length: [
                  {
                    cond: 'between',
                    low: 6,
                    high: 30,
                    message: 'The nickname needs to have between 6 and 30 characters',
                  },
                ],
                pattern: [
                  {
                    message: 'The nickname is invalid',
                    type: 'nickName',
                  },
                ],
              },
              startAdornment: <Badge />,
              type: 'input',
              value: user.nickName,
            },
            {
              id: 'phoneNumber',
              error: errors['phoneNumber'],
              placeholder: 'Phone Number',
              rules: {
                pattern: [
                  {
                    message: 'The phone number is invalid',
                    type: 'phoneNumber',
                  },
                ],
              },
              startAdornment: <ContactPhone />,
              type: 'input',
              value: user.phoneNumber,
            },
          ],
        ]}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Profile successfuly updated"
      />
    </div>
  );
}

export default ProfileAccount;
