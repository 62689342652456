import {Error as MuiError} from '@mui/icons-material';
import {FormHelperText, styled} from '@mui/material';

import {ErrorProps} from './Error.props';

const Container = styled('div')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 10px 0 0',
  width: '100%',
}));

export const Error = (props: ErrorProps) => (
  <Container>
    <FormHelperText id={`${props.id}-error`}>{props.message}</FormHelperText>
    <MuiError color="error" />
  </Container>
);
