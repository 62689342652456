export const updateProperty = (obj: Record<string, any>, property: string, value: any) => {
  const tmp = obj;
  tmp[property] = value;
  return tmp;
};

export const extractProps = (props: Record<string, any>, toBeRemoved: Array<string>) => {
  const all: Record<string, any> = {};
  let k: keyof typeof props;

  for (k in props) {
    if (toBeRemoved.indexOf(k) < 0) {
      all[k] = props[k];
    }
  }
  return all;
};
