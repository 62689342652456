import jwt_decode from 'jwt-decode';

import {setAuthorizationHeader} from '../../api';
import {Role} from './Role';
import {Token} from './Token';
import {UserSettings} from './UserSettings';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  nickName: string;
  email: string;
  role: Role;
  token: string;
  enrolledContests: Array<number>;
  status?: string;
  settings: UserSettings;
  phoneNumber?: string;

  constructor(data: any) {
    setAuthorizationHeader(data.token!);

    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.nickName = data.nickName;
    this.email = data.email;
    this.role = data.role;
    this.token = data.token;
    this.enrolledContests = data.enrolledContests;
    this.settings = data.settings;
    this.phoneNumber = data.phoneNumber;
  }

  public store(): void {
    localStorage.setItem('id', this.id.toString());
    localStorage.setItem('firstName', this.firstName);
    localStorage.setItem('lastName', this.lastName);
    localStorage.setItem('nickName', this.nickName);
    localStorage.setItem('email', this.email);
    localStorage.setItem('role', this.role);
    localStorage.setItem('token', this.token);
    localStorage.setItem('enrolledContests', JSON.stringify(this.enrolledContests));
    localStorage.setItem('userSettings', JSON.stringify(this.settings));
    localStorage.setItem('phoneNumber', this.phoneNumber ?? '');
  }

  public static isInvalid(token: string) {
    try {
      const decoded = jwt_decode(token) as Token;
      return !decoded || !decoded.exp || Date.now() / 1000 > decoded.exp;
    } catch (e) {
      return true;
    }
  }

  public static load(logoutCallback: () => void): undefined | User {
    const token = localStorage.getItem('token');
    if (token && User.isInvalid(token)) {
      User.remove();
      logoutCallback();
      return;
    }

    const id = localStorage.getItem('id');
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const nickName = localStorage.getItem('nickName');
    const email = localStorage.getItem('email');
    const role = localStorage.getItem('role') as Role;
    const phoneNumber = localStorage.getItem('phoneNumber');

    let enrolledContests;

    try {
      enrolledContests = JSON.parse(localStorage.getItem('enrolledContests') || '{}');
    } catch (e) {
      enrolledContests = [];
    }

    let settings;

    try {
      settings = JSON.parse(localStorage.getItem('userSettings') || '{}');
    } catch (e) {
      settings = [];
    }

    if ([firstName, lastName, nickName, email, role, token, enrolledContests, settings].indexOf(null) >= 0) {
      return undefined;
    }

    return new User({
      id: id!,
      firstName: firstName!,
      lastName: lastName!,
      nickName: nickName!,
      email: email!,
      role: role,
      token: token!,
      enrolledContests: enrolledContests!,
      settings: settings!,
      phoneNumber: phoneNumber,
    });
  }

  public static remove(): void {
    localStorage.removeItem('id');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('nickName');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('enrolledContests');
    localStorage.removeItem('userSettings');
    localStorage.removeItem('phoneNumber');
  }

  public enrollToContest(contestId: number) {
    this.enrolledContests.push(contestId);
    localStorage.setItem('enrolledContests', JSON.stringify(this.enrolledContests));
  }
}
