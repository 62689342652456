import {useTheme} from '@mui/material/styles';

import {Ellipse, Page} from '../../components';
import {EllipseSize as Size, Position} from '../../types';
import {ContactDetails} from './ContactDetails';
import {ContactForm} from './ContactForm';

export const Contact = () => {
  const theme = useTheme();
  return (
    <Page>
      <Ellipse
        backgroundColor={theme.palette.secondary.main}
        bounds={{position: Position.BOTTOM_LEFT}}
        height={375}
        size={Size.TOP_RIGHT_QUARTER}
        width={400}
      />

      <div className="flex items-center min-h-[80vh] pt-[3vh] z-10">
        <div className="grid grid-cols-12 justify-center items-center h-[550px]">
          <div className=" bg-card-main col-span-6 h-full rounded-l-2xl">
            <ContactForm />
          </div>
          <div className=" bg-secondCard-main col-span-6 h-full rounded-r-2xl">
            <ContactDetails />
          </div>
        </div>
      </div>
    </Page>
  );
};
