import {breakpoints} from '../grid/Breakpoints';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h5_black: true;
    h5_italic: true;
    h6_grey: true;
    h7: true;
    subtitle1_black: true;
  }
}

export const typography = {
  allVariants: {
    color: '#FFFFFF',
  },
  button: {
    fontSize: 18,
  },
  fontFamily: ['Nunito Sans', 'San Francisco', 'Segoe UI', 'Helvetica Neue', 'sans-serif'].join(','),
  h1: {
    fontSize: '3.5rem',
    fontWeight: 900,

    [breakpoints.up_to_sm.css]: {
      fontSize: '2.75rem',
    },

    [breakpoints.up_to_lg.css]: {
      fontSize: '3rem',
    },
  },
  h2: {
    fontFamily: 'Poppins',
    fontSize: '2.75rem',
    fontWeight: 600,
  },
  h3: {
    fontFamily: 'Poppins',
    fontSize: '2rem',
    fontWeight: 600,
  },
  h4: {
    fontFamily: 'Poppins',
    fontSize: '1.75rem',
    fontWeight: 400,
  },
  h5_italic: {
    fontFamily: 'Poppins',
    fontSize: '1.25rem',
    fontStyle: 'italic',
    fontWeight: 300,
  },
  h5_black: {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '1.25rem',
    fontWeight: 900,
  },
  h6: {
    fontFamily: 'Poppins',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  h6_grey: {
    color: '#9098B1',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  h7: {
    fontFamily: 'Poppins',
    fontSize: '1.15rem',
    fontWeight: 600,
  },
  subtitle1: {
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontWeight: 300,
  },
  subtitle1_black: {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'italic',
    fontWeight: 300,
  },
};
