import {useContext, useEffect, useReducer, useState} from 'react';

import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {Close, Cancel} from '@mui/icons-material';

import {AuthorContestsResponse, AuthorEntry} from '../../../../api/responses/AuthorsResponseTypes';
import {request, URLs} from '../../../../api';
import AppContext from '../../../../context/AppContext';
import {ModuleType} from '../../../../types/entities/ModuleType';
import {theme} from '../../../../types/theme/Theme';
import {contestsPaginationStyle} from '../../../contests/contests-table/contest-table-styles';

type AuthorContestsManagementProps = {
  author: AuthorEntry;
  isOpen: boolean;
  onClose: () => void;
};

function AuthorContestsManagement({author, isOpen, onClose}: AuthorContestsManagementProps) {
  const {dispatchError} = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const [page, setPage] = useState<number>(0);

  const [allModules, setAllModules] = useState<Array<ModuleType>>([]);

  const [authorContests, setAuthorContests] = useState<AuthorContestsResponse>([]);
  const [contestIdToUnassign, setContestIdToUnassign] = useState<number | null>();

  useEffect(() => {
    request<{}, {}, Array<ModuleType>>(URLs.getAllModules, {
      method: 'GET',
      successCallback: (response) => {
        setAllModules(response);
        setIsLoading(false);
      },
      errorCallback: () => {
        dispatchError({errorMessage: 'There was an error. Please try again later.', redirectURL: '/staff/authors'});
      },
    });
  }, []);

  useEffect(() => {
    request<{}, {}, AuthorContestsResponse>(URLs.getAuthorContests(author.authorId), {
      method: 'GET',
      successCallback: (response) => {
        setAuthorContests(response ?? []);
        setIsLoading(false);
      },
      errorCallback: () => {
        dispatchError({errorMessage: 'There was an error. Please try again later.', redirectURL: '/staff/authors'});
      },
    });
  }, [update]);

  const unassingAuthorFromContest = (contestId: number) => {
    if (contestId) {
      request<{}, {}, {}>(URLs.unassignAuthorFromContest(contestId, author.authorId), {
        method: 'DELETE',
        successCallback: () => {
          forceUpdate();
        },
        errorCallback: () => {
          dispatchError({errorMessage: 'There was an error. Please try again later.', redirectURL: '/staff/authors'});
        },
      });
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={onClose}>
        <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[50%] h-fit min-h-[60%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          <div className="absolute right-1 px-2">
            <IconButton onClick={onClose}>
              <Close color="success" sx={{color: 'white'}} />
            </IconButton>
          </div>
          {isLoading ? (
            <div className="flex h-full justify-center items-center content-center">
              <CircularProgress color="success" size={68} />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-4 py-3 px-2">
              <Typography variant="h4">{`${author.firstName} ${author.lastName}'s Contests`}</Typography>
              <div className="flex flex-col w-full">
                <Table
                  sx={{
                    '& .MuiTableRow-root:hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiTableCell-root': {
                      borderColor: theme.palette.card.light,
                      color: theme.palette.card.contrastText,
                    },
                    backgroundColor: theme.palette.card.dark,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell> No. </TableCell>
                      <TableCell> Contest Name </TableCell>
                      <TableCell> Module Name </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody style={{borderColor: theme.palette.card.dark}}>
                    {authorContests &&
                      authorContests.slice(page * 5, page * 5 + 5).map((authorContest, index) => (
                        <TableRow key={authorContest.contestId}>
                          <TableCell> {index + 1} </TableCell>

                          <TableCell> {authorContest.contestName}</TableCell>
                          <TableCell>
                            {allModules.find((am) => am.id == authorContest.moduleId)?.module_name ?? '-'}
                          </TableCell>

                          <TableCell>
                            <IconButton onClick={() => setContestIdToUnassign(authorContest.contestId)} color="warning">
                              <Cancel />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {authorContests && authorContests.length === 0 && (
                  <div className="flex justify-center py-3 bg-slate-600">
                    <Typography variant="h6">This author has no assigned contests</Typography>
                  </div>
                )}
                {authorContests && (
                  <div className="flex flex-row justify-end">
                    <TablePagination
                      sx={contestsPaginationStyle(theme)}
                      rowsPerPageOptions={[5]}
                      component="div"
                      count={authorContests.length}
                      rowsPerPage={5}
                      page={page}
                      onPageChange={(_, page) => setPage(page)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      {contestIdToUnassign && (
        <Modal
          open={contestIdToUnassign != null}
          onClose={() => {
            setContestIdToUnassign(null);
          }}
        >
          <div className="flex flex-col items-center gap-4 py-5 px-5 bg-background-default top-2/4 left-2/4 w-[40%] h-fit p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <Typography variant="h4">Confirm action</Typography>
            <Typography variant="subtitle2">
              You are about to unassign an author from a contest. This action will remove the author's ability to read
              or write to this specific contest. However, the author will retain read access if they are still assigned
              to the module that includes this contest.
            </Typography>
            <div className="flex flex-row gap-5 items-center justify-center">
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  unassingAuthorFromContest(contestIdToUnassign);
                  setContestIdToUnassign(null);
                }}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="gridPrimary"
                onClick={() => {
                  setContestIdToUnassign(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AuthorContestsManagement;
