import {useState} from 'react';

import {Button, Typography} from '@mui/material';

import {URLs, sendPostRequest} from '../../../api';
import {ImportUsersInBulkResponse} from '../../../api/responses/ImportUsersInBulkResponseTypes';

function ImportUsersView() {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [error, setError] = useState<string>();

  const [newImportedUsers, setNewImportedUsers] = useState<ImportUsersInBulkResponse['importedUsers']['newUsers']>();
  const [existingImportedUsers, setExistingImportedUsers] =
    useState<ImportUsersInBulkResponse['importedUsers']['existingUsers']>();
  const [failedUsers, setFailedUsers] = useState<ImportUsersInBulkResponse['failedUsers']>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      sendPostRequest(URLs.importUsers, formData)
        .then((res) => {
          setSelectedFile(undefined);
          setError(undefined);

          const response = res.data as ImportUsersInBulkResponse;

          setNewImportedUsers(response.importedUsers.newUsers);
          setExistingImportedUsers(response.importedUsers.existingUsers);
          setFailedUsers(response.failedUsers);
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.message) {
            setError(err.response.data.message);
          } else {
            setError('An error occurred while importing users.');
          }
        });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Typography variant="h3">Import Users</Typography>
        <Typography variant="body1">
          Upload the CSV file to import your users. After a successful import, all users will receive &quot;set
          password&quot; emails. Keep in mind that all imported users are automatically verified.
        </Typography>
        <Typography variant="body1">
          Note that you can import existing users, they will be assigned to the specified groups, while their current
          group assignments remain unchanged. In this case, all other user information, besides the email and the
          specified groups, will be ignored; ONLY the email is serving as the identifying field for existing users.
        </Typography>
      </div>
      <div className="flex flex-col gap-2">
        <Typography variant="body1">
          The CSV file should be formatted as follows (<b>this should be the first line</b>):
        </Typography>
        <span className="bg-gray-800 text-yellow-400 p-2 rounded-md w-fit">
          First Name,Last Name,Nickname,Phone Number,Role,Email,group1;group2;group3,module1;module2;module3
        </span>
      </div>

      <Typography variant="body1">Select a CSV file to upload:</Typography>
      <input type="file" name="file" accept=".csv" onChange={handleFileChange} />
      <Button variant="contained" onClick={handleUpload} disabled={!selectedFile}>
        Upload
      </Button>

      <div className="flex flex-col gap-2">
        {error && <div className="text-red-600 font-bold">{error} - Operation failed!</div>}
        {newImportedUsers && newImportedUsers.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="text-green-600 font-semibold">
              The following new users were imported and assigned successfully:
            </span>
            <div className="flex flex-col gap-2">
              {newImportedUsers.map((newUser) => (
                <div key={newUser.id} className="flex flex-row gap-2">
                  <Typography variant="body1">
                    {newUser.firstName} {newUser.lastName} - ({newUser.email})
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
        {existingImportedUsers && existingImportedUsers.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="text-yellow-500 font-semibold">
              The following existing users were imported and assigned successfully:
            </span>
            <div className="flex flex-col gap-2">
              {existingImportedUsers.map((existingUser) => (
                <div key={existingUser.id} className="flex flex-col gap-2">
                  <Typography variant="body1">
                    {existingUser.firstName} {existingUser.lastName} - ({existingUser.email})
                  </Typography>
                  {existingUser.failedGroups &&
                    existingUser.failedGroups.length > 0 &&
                    existingUser.failedGroups.map((failedGroup, index) => (
                      <div className="text-orange-400 ml-2 flex flex-col" key={`${failedGroup.error}-${index}`}>
                        {`Group ${failedGroup.groupName} - ${failedGroup.error}`}
                      </div>
                    ))}
                  {existingUser.failedModules &&
                    existingUser.failedModules.length > 0 &&
                    existingUser.failedModules.map((failedModule, index) => (
                      <div className="text-orange-400 ml-2 flex flex-col" key={`${failedModule.error}-${index}`}>
                        {`Module ${failedModule.groupName} - ${failedModule.error}`}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        )}
        {failedUsers && failedUsers.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="text-red-600 font-semibold">The following users failed to be imported: </span>
            <div className="flex flex-col gap-2">
              {failedUsers.map((faliedNewUser) => (
                <div key={faliedNewUser.email} className="flex flex-row gap-2">
                  <Typography variant="body1">
                    {`${faliedNewUser.firstName} ${faliedNewUser.lastName} - (${faliedNewUser.email}) - Reason: ${faliedNewUser.error}`}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImportUsersView;
