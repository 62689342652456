import {useContext, useEffect, useMemo, useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import Select from 'react-select';

import {Group} from '../../../types/entities/Group';
import {sendGetRequest, URLs} from '../../../api';
import {GroupUsers} from './GroupUsers';
import {AdminUserAssign} from './AdminUserAssign';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import {StaffPageOutletProps} from '../StaffPage';
import AppContext from '../../../context/AppContext';
import {Role} from '../../../types';
import UserAssign from './UserAssign';

export const StudentsManagement = () => {
  const {user} = useContext(AppContext);
  const {handleHttpError}: StaffPageOutletProps = useOutletContext();

  const [groups, setGroups] = useState<Array<Group>>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [refreshSelectedGroup, setRefreshSelectedGroup] = useState(false);

  const getAllGroups = () => {
    sendGetRequest(URLs.accessibleUserGroups)
      .then((response) => {
        setGroups(response.data);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  const groupOptions = useMemo(
    () => (groups ? groups.map((group) => ({value: group.id, label: group.name})) : []),
    [groups]
  );

  const dropdownStyles = dropdownStylesConfig();

  return (
    <>
      <div className="flex flex-row justify-between w-full">
        <div className="w-96">
          <Select
            id="select-group"
            placeholder="Select group"
            defaultValue={selectedGroup?.name}
            onChange={(event) => {
              const selectedId = (event as {value: string; label: string}).value;
              const group = groups.filter((gr) => gr.id === Number(selectedId))[0];
              setSelectedGroup(group);
            }}
            options={groupOptions}
            styles={dropdownStyles}
          />
        </div>
        {groups && groups.length > 0 && (
          <div>
            {selectedGroup &&
              (user?.role === Role.ADMIN ? (
                <AdminUserAssign
                  selectedGroup={selectedGroup}
                  onAssignFail={(e) => handleHttpError(e)}
                  onAssignSuccess={() => setRefreshSelectedGroup(!refreshSelectedGroup)}
                />
              ) : (
                <UserAssign
                  selectedGroup={selectedGroup}
                  onAssignFail={(e) => {
                    handleHttpError(e.response.data.error);
                  }}
                  onAssignSuccess={() => setRefreshSelectedGroup(!refreshSelectedGroup)}
                />
              ))}
          </div>
        )}
      </div>
      {selectedGroup && (
        <GroupUsers
          selectedGroup={selectedGroup}
          refreshSelectedGroup={refreshSelectedGroup}
          handleHttpError={handleHttpError}
        />
      )}
    </>
  );
};
