import {useState} from 'react';
import {NavLink} from 'react-router-dom';

import {styled} from '@mui/material/styles';
import {Button as MuiButton, Divider, Drawer as MuiDrawer, List, ListItem, IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import GenericMenuProps from '../generic-menu/GenericMenu.props';
import {Logo} from '../../logo/Logo';
import {Button} from '../../index';

const Drawer = styled(MuiDrawer)(({theme}) => ({
  '.MuiDrawer-paper': {
    background: theme.palette.background.default,
  },
}));

export const MobileMenu = (props: GenericMenuProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="absolute top-[10px] left-[-52px]">
        <IconButton onClick={() => setOpen(true)}>
          <MenuIcon sx={{fontSize: 28}} className=" text-white" />
        </IconButton>
      </div>
      <div className="flex flex-row items-start w-full bg-background-default py-3 ">
        <MuiButton onClick={() => setOpen(true)} variant="text">
          <Logo />
        </MuiButton>
        <Drawer anchor="left" onClose={() => setOpen(false)} open={open}>
          <div
            className="w-[250px] aria-[presentation]"
            onClick={() => setOpen(false)}
            onKeyDown={() => setOpen(false)}
          >
            <div className="text-center p-4">
              <NavLink className="text-3xl text-white font-semibold no-underline w-full" to={props.logo}>
                Devmind
              </NavLink>
            </div>
            <Divider />
            <List>
              {props.links.map((link, index) => (
                <ListItem button alignItems="center" key={index}>
                  <NavLink className="text-white text-xl font-semibold p-2" to={link.to}>
                    {link.text.toUpperCase()}
                  </NavLink>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {props.buttons.map(({color, link, onClick, text, size}, index) => (
                <ListItem button key={index}>
                  <Button color={color} fullWidth={true} label={text} link={link} onClick={onClick} size={size} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    </>
  );
};
