import Clear from '@mui/icons-material/Clear';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

type ModuleMebmersTableProps = {
  users: Array<{id: number; firstName: string; lastName: string; email: string}>;
  onUnassignCallback: (memberId: number) => void;
};

function ModuleMebmersTable({users, onUnassignCallback}: ModuleMebmersTableProps) {
  const theme = useTheme();

  return (
    <div className="w-full py-2">
      <TableContainer>
        <Table
          sx={{
            minWidth: 800,
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTableCell-root': {
              borderColor: theme.palette.card.light,
              color: theme.palette.card.contrastText,
            },
            '& .Mui-active': {
              color: `${theme.palette.primary.main} !important`,
            },
            '& .MuiTableSortLabel-root:hover': {
              color: `${theme.palette.primary.light} !important`,
            },
            '& .MuiTableSortLabel-icon': {
              color: `${theme.palette.primary.main} !important`,
            },
            backgroundColor: theme.palette.card.dark,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody style={{borderColor: theme.palette.card.dark}}>
            {users &&
              users.map((user, index) => (
                <TableRow
                  sx={{
                    '&:last-child td': {
                      borderBottom: 3,
                      borderColor: theme.palette.card.light,
                    },
                  }}
                  key={user.id}
                >
                  <TableCell> {index + 1} </TableCell>
                  <TableCell> {user.firstName} </TableCell>
                  <TableCell> {user.lastName} </TableCell>
                  <TableCell> {user.email} </TableCell>

                  <TableCell>
                    <IconButton onClick={() => onUnassignCallback(user.id)} sx={{color: 'white'}}>
                      <Clear />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {users.length === 0 && (
          <div className="flex justify-center items-center py-4 bg-card-dark">
            <Typography variant="h5">No data to be displayed...</Typography>
          </div>
        )}
      </TableContainer>
    </div>
  );
}

export default ModuleMebmersTable;
