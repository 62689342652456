import {EllipseSize as Size} from '../types';

export function round(size: Size, length: number) {
  switch (size) {
    case Size.FULL:
      return {
        bottomLeft: length,
        bottomRight: length,
        topLeft: length,
        topRight: length,
      };
    case Size.TOP_HALF:
      return {
        topLeft: length,
        topRight: length,
      };
    case Size.LEFT_HALF:
      return {
        bottomLeft: length,
        topLeft: length,
      };
    case Size.BOTTOM_HALF:
      return {
        bottomLeft: length,
        bottomRight: length,
      };
    case Size.RIGHT_HALF:
      return {
        bottomRight: length,
        topRight: length,
      };
    case Size.TOP_LEFT_QUARTER:
      return {
        topLeft: length,
      };
    case Size.TOP_RIGHT_QUARTER:
      return {
        topRight: length,
      };
    case Size.BOTTOM_LEFT_QUARTER:
      return {
        bottomLeft: length,
      };
    case Size.BOTTOM_RIGHT_QUARTER:
      return {
        bottomRight: length,
      };
    default:
      return {};
  }
}
