import {useState} from 'react';

import {DateTimePicker as MuiDatePicker, LocalizationProvider} from '@mui/lab';
import {TextField} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import {InputProps} from '../../../types';

export const DatePicker = (props: InputProps) => {
  const [value, setValue] = useState<string | null>(props.value);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        value={value}
        onChange={(newDate) => {
          if (props.onChange) {
            props.onChange(null, newDate);
          }
          setValue(newDate);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};
