import {useState} from 'react';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useTheme} from '@mui/system';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {DoneAll, DeleteOutline, Edit} from '@mui/icons-material';
import {DateTimePicker, LocalizationProvider} from '@mui/lab';

import {sendDeleteRequest, sendPutRequest, URLs} from '../../../api';
import {DateTimeTextField} from '../../../components/form/datetime-textfield/DateTimeTextField';
import {CurriculumGroupToContest} from '../../../api/responses/CurriculumResponseTypes';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import {formatDate} from '../../../utils/date-utils';

type CurriculumProgressProps = {
  curriculumProgress: Array<CurriculumGroupToContest>;
  handleHttpError: (err: any) => void;
  onSuccess: () => void;
};

export const CurriculumProgress = ({curriculumProgress, handleHttpError, onSuccess}: CurriculumProgressProps) => {
  const theme = useTheme();
  const [editInfo, setEditInfo] = useState<{id?: string; startDate?: Date; endDate?: Date}>();
  const [editEnabled, setEditEnabled] = useState(false);

  const [showUnassignModal, setShowUnassignModal] = useState<boolean>();
  const [groupContestToUnassign, setGroupContestToUnassign] = useState<
    {groupId: number; contestId: number} | undefined
  >();

  const onSavePressed = () => {
    sendPutRequest(URLs.editContestToGroup, {
      id: editInfo?.id,
      startDate: editInfo?.startDate,
      endDate: editInfo?.endDate,
    })
      .then((_response) => {
        setEditEnabled(false);
        setEditInfo({});
        onSuccess();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const handleAssignedContestDeletion = () => {
    sendDeleteRequest(URLs.unassignGroupContestFromGroup, {
      groupId: groupContestToUnassign?.groupId,
      contestId: groupContestToUnassign?.contestId,
    })
      .then((response) => {
        if (response.status !== 204) {
          handleHttpError('An error occured while trying to unassign the contest !');
        }
        onSuccess();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  return (
    <div className="flex w-full justify-center">
      <TableContainer className="bg-card-dark">
        <Table
          sx={{
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTableCell-root': {
              borderColor: theme.palette.card.light,
              color: theme.palette.card.contrastText,
            },
            backgroundColor: theme.palette.card.dark,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Contest Name (Course)</TableCell>
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {curriculumProgress &&
              curriculumProgress.map((row: any) => (
                <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2">{row.title}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {editEnabled && editInfo?.id === row.id ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Start Date"
                          inputFormat="dd/MMM/yyyy hh:mm a"
                          value={editInfo?.startDate}
                          onChange={(date) => {
                            if (date) {
                              setEditInfo({...editInfo, startDate: date});
                            }
                          }}
                          renderInput={(params) => <DateTimeTextField {...params} />}
                        />
                      </LocalizationProvider>
                    ) : (
                      <Typography variant="subtitle2">{formatDate(row.start_date.toString())}</Typography>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {editEnabled && editInfo?.id === row.id ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="End Date"
                          inputFormat="dd/MMM/yyyy hh:mm a"
                          value={editInfo?.endDate}
                          onChange={(date) => {
                            if (date) {
                              setEditInfo({...editInfo, endDate: date});
                            }
                          }}
                          renderInput={(params) => <DateTimeTextField {...params} />}
                        />
                      </LocalizationProvider>
                    ) : (
                      <Typography variant="subtitle2">{formatDate(row.end_date.toString())}</Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {!(editEnabled && editInfo?.id === row.id) && (
                      <IconButton
                        onClick={() => {
                          setEditInfo({
                            id: row.id,
                            startDate: row.start_date,
                            endDate: row.end_date,
                          });
                          setEditEnabled(true);
                        }}
                        sx={{color: 'white'}}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {editInfo?.id === row.id && (
                      <IconButton
                        onClick={() => {
                          onSavePressed();
                        }}
                        color={'secondary'}
                      >
                        <DoneAll />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setGroupContestToUnassign({groupId: row.groupId, contestId: row.contest_id});
                        setShowUnassignModal(true);
                      }}
                      color="warning"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!curriculumProgress && (
          <div className="text-center p-3 ">
            <Typography variant="h5">No contests assigned to this group!</Typography>
          </div>
        )}
      </TableContainer>
      {showUnassignModal && (
        <ConfirmationModal
          message={
            'You are about to unassign a contest from a group. All the users submissions and rankings will be lost. Are you sure?'
          }
          onClose={() => setShowUnassignModal(false)}
          onAccept={() => {
            handleAssignedContestDeletion();
            setShowUnassignModal(false);
          }}
        />
      )}
    </div>
  );
};
