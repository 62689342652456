import {ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';

import {InputProps as Props} from '../../types';

export const Toggle = (props: Omit<Props, 'fullWidth' | 'rules'>) => (
  <>
    <Typography variant="h6" sx={{marginBottom: '10px', marginTop: '10px'}}>
      {props.title}
    </Typography>
    <ToggleButtonGroup
      exclusive
      className={props.className}
      fullWidth={true}
      onChange={props.onChange}
      value={props.value}
    >
      {(props.options ?? []).map((option) => (
        <ToggleButton
          id={props.id}
          value={option}
          sx={{
            borderColor: 'white',
            color: 'white',
            '&.Mui-selected': {
              backgroundColor: '#8CC63F',
              color: 'white',
            },
            '&:hover': {
              backgroundColor: '#324616',
              color: 'white',
            },
            height: props.maxHeight,
          }}
        >
          {option.toUpperCase()}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  </>
);
