import {useMemo} from 'react';
import {useNavigate, useOutletContext, useParams, useSearchParams} from 'react-router-dom';

import {Problem} from '../../types';
import {ContestSubmission} from '../../types/entities/ContestSubmission';
import {SubmissionModal} from '../../components/contest-card/ContestSubmissionModal';

export const ContestSubmissionView = () => {
  const context = useOutletContext<{submissions: Array<ContestSubmission>; problems: {[id: number]: Problem}}>();
  const navigate = useNavigate();
  const {submissionId} = useParams();
  const [searchParams, _] = useSearchParams();

  const submission = useMemo(
    () => context?.submissions.find((submission) => submission.id === Number(submissionId)),
    [submissionId]
  );

  const problem = useMemo(() => context?.problems[submission?.problemId || -1], [submission]);

  return submission ? (
    <SubmissionModal
      submission={submission}
      problem={problem}
      onClose={() => {
        navigate({pathname: '..', search: searchParams.toString()}, {replace: true});
      }}
    />
  ) : null;
};
