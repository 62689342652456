import {Role} from '../types';

export const rolesMapping = (userRoleCode: number): Role => rolesMap[userRoleCode];

const rolesMap: {[key: number]: Role} = {
  1: Role.STUDENT,
  2: Role.TEACHER,
  3: Role.ADMIN,
  4: Role.CREATOR,
  5: Role.GUEST,
  6: Role.AUTHOR,
  7: Role.COURSE_ADMIN,
  8: Role.AUTHOR_TEACHER,
};

export const fromRoleNumberToString = (role: number): string => {
  switch (role) {
    case 1:
      return 'STUDENT';
    case 2:
      return 'TEACHER';
    case 3:
      return 'ADMIN';
    case 4:
      return 'CREATOR';
    case 5:
      return 'GUEST';
    case 6:
      return 'AUTHOR';
    case 7:
      return 'COURSE-ADMIN';
    case 8:
      return 'AUTHOR-TEACHER';
    default:
      return 'UNKNOWN';
  }
};
