export enum Language {
  C = 'C',
  CPP = 'C++',
  CPP2 = 'cpp',
  GO = 'Go',
  JAVA = 'Java',
  JAVASCRIPT = 'JavaScript',
  PYTHON = 'Python',
  RUBY = 'Ruby',
  BASH = 'bash',
}

export const languageOptions = [
  {languageId: 1, label: 'Java', value: 'java'},
  {languageId: 2, label: 'C', value: 'c'},
  {languageId: 3, label: 'C++', value: 'cpp'},
  {languageId: 4, label: 'Python', value: 'python'},
];
