export enum Status {
  FAILED = 'FAILED',
  PASSED = 'PASSED',
  RUNTIME_ERROR = 'RUNTIME_ERROR',
}

export class Result {
  status: Status;
  input: string | null;
  score: number | null;
  out: string;
  ref: string;

  constructor(data: any) {
    this.status = data.status;
    this.input = data.input;
    this.out = data.out;
    this.ref = data.ref;
    this.score = data.score;
  }
}
