import {useNavigate, useSearchParams} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';

import {Typography} from '@mui/material';

import {Page, RedirectModal} from '../../../components';
import AppContext from '../../../context/AppContext';
import {request, URLs} from '../../../api';
import {Color} from '../../../types';

export const VerifyAccount = () => {
  const {dispatchError} = useContext(AppContext);

  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();

  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!searchParams.has('email') || !searchParams.has('token')) {
      dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/login'});
      return;
    }

    request<{email: string; token: string}, {}, {}>(URLs.verifyAccount, {
      method: 'GET',
      params: {email: searchParams.get('email')!, token: searchParams.get('token')!},
      successCallback: () => {
        setSuccess(true);
      },
      errorCallback: () => {
        dispatchError({
          errorMessage:
            "It seems your verification link has expired. You can activate your account by resetting your password, you'll be redirected to the password reset page.",
          redirectURL: '/reset-password',
          buttonText: 'Reset Password',
          hasCloseIconButton: true,
        });
      },
    });
  }, [searchParams]);

  return (
    <Page>
      <div>
        {' '}
        {success === true && (
          <RedirectModal
            buttonLabel={'Continue'}
            redirectUrl={'/login'}
            buttonColor={Color.PRIMARY}
            children={[
              <Typography variant="h2">Success</Typography>,
              <Typography variant="h4">
                You successfully activated your account, you'll be redirected to the login page.
              </Typography>,
            ]}
          />
        )}
      </div>
    </Page>
  );
};
