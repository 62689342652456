import {Typography} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

type ContestDescriptionProps = {
  about: string;
  description: string;
  prize: string;
  rules: string;
};

export const ContestDescription = (contestDescriptionProps: ContestDescriptionProps) => (
  <div className="flex flex-col w-100">
    {Object.entries(contestDescriptionProps).map(([key, value]) => (
      <div className="py-2">
        <Typography variant="h5" className="font-bold">
          {`${key.slice(0, 1).toLocaleUpperCase()}${key.slice(1)}`}
        </Typography>
        <MDEditor.Markdown source={value} style={{backgroundColor: 'transparent', listStyle: 'disc !important'}} />
      </div>
    ))}
  </div>
);
