import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';

import {CssBaseline, ThemeProvider} from '@mui/material';

import AppContext, {ContextData} from './context/AppContext';
import Router from './routes/Router';
import {theme} from './types/theme/Theme';
import {User} from './types/entities/User';
import ErrorModalDataType from './types/generics/ErrorModalDataType';
import ErrorWrapper from './utils/wrappers/ErrorWrapper';
import {languageOptions} from './types/entities/Language';

function App() {
  const navigate = useNavigate();

  const [user, setUser] = useState<User | undefined>(User.load(() => navigate('/login')));

  const [errorState, setErrorState] = useState<ErrorModalDataType>({
    isErrorEnabled: false,
    message: '',
    buttonText: 'Go Back',
    hasCloseIconButton: false,
  });

  // TODO: fetch available languages and set the context
  // const [languages, setLanguages] = useState<Record<number, string>>({});

  const loginCallback = (data: any) => {
    updateData(data);
    navigate('/contests');
  };

  const updateData = (data: any) => {
    const newUser = new User(data);
    newUser.store();
    setUser(newUser);
  };

  const logoutCallback = () => {
    User.remove();
    setUser(undefined);
    navigate('/login');
  };

  const setModalError = ({
    errorMessage,
    buttonText,
    redirectURL,
    hasCloseIconButton,
  }: {
    errorMessage: string;
    buttonText?: string;
    redirectURL?: string;
    hasCloseIconButton?: boolean;
  }) => {
    setErrorState({isErrorEnabled: true, message: errorMessage, redirectURL, buttonText, hasCloseIconButton});
  };

  const clearError = () => {
    setErrorState({isErrorEnabled: false, message: null});
  };

  useEffect(() => {
    if (window.location.pathname === '/' && user !== undefined) {
      navigate('/contests');
    }
  }, [user, navigate]);

  // useEffect(() => {
  //   // TODO: get languages & set
  //   setLanguages({1: 'Java'})
  // }, []);

  return (
    <AppContext.Provider
      value={
        new ContextData(
          user,
          loginCallback,
          logoutCallback,
          {
            ...languageOptions.reduce((acc: Record<number, string>, language) => {
              acc[language.languageId] = language.label;
              return acc;
            }, {}),
          },
          errorState,
          setModalError,
          clearError,
          updateData
        )
      }
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorWrapper>
          <Router role={user?.role} />
        </ErrorWrapper>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
