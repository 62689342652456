import {useTheme} from '@mui/material/styles';

import {Ellipse, Page} from '../../../components';
import {LoginForm as Form} from './Form';
import {EllipseSize as Size, Position} from '../../../types/generics';

export const Login = () => {
  const theme = useTheme();
  return (
    <Page>
      <Ellipse
        backgroundColor={theme.palette.secondary.main}
        bounds={{
          maxHeight: 150,
          paddingTop: 150,
          position: Position.BOTTOM_CENTER,
        }}
        height={300}
        size={Size.TOP_HALF}
        width={700}
      />

      <div className="flex items-center min-h-[80vh] z-10">
        <div className="grid justify-center bg-card-main rounded-2xl">
          <Form />
        </div>
      </div>
    </Page>
  );
};
