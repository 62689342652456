import {alpha, PaletteOptions} from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    card: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
    secondCard: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
    // TODO: use better names
    gridPrimary: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
    gridSecondary: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
    inputBox: {
      backgroundColor: string;
      errorBackgroundColor: string;
    };
    selectBox: {
      control: {
        backgroundColor: string;
      };
      option: {
        backgroundColor: string;
        selectedBackgroundColor: string;
        focusBackgroundColor: string;
      };
    };
  }

  interface PaletteOptions {
    card: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
    secondCard: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
    // TODO: use better names
    gridPrimary: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
    gridSecondary: {
      contrastText: string;
      dark: string;
      light: string;
      main: string;
    };
  }

  interface TypeText {
    main: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gridPrimary: true;
    gridSecondary: true;
    transparent: true;
  }
}

export const palette = {
  background: {
    contrastText: '#FFFFFF',
    dark: '#121D39',
    default: '#001A35',
    light: '#2D3A59',
    main: '#001A35',
  },
  card: {
    contrastText: '#FFFFFF',
    dark: '#293450',
    light: '#424E6A',
    main: '#2D3A59',
  },
  error: {
    contrastText: '#CD5C5C',
    dark: '#CD5C5C',
    light: '#CD5C5C',
    main: '#CD5C5C',
  },
  primary: {
    contrastText: '#FFFFFF',
    dark: '#324616',
    light: '#879f66',
    main: '#547625',
  },
  secondary: {
    contrastText: '#FFFFFF',
    dark: '#628a2c',
    light: '#aed778',
    main: '#8CC63F',
  },
  secondCard: {
    contrastText: '#FFFFFF',
    dark: '#0E172D',
    light: '#273047',
    main: '#0F1932',
  },
  text: {
    main: '#FFFFFF',
  },
  gridPrimary: {
    contrastText: '#FFFFFF',
    dark: '#204CC6',
    light: '#204CC6',
    main: '#204CC6',
  },
  transparent: {
    contrastText: alpha('#D1D1D1', 1),
    dark: alpha('#FFFFFF', 0),
    light: alpha('#FFFFFF', 0),
    main: alpha('#FFFFFF', 0),
  },
  gridSecondary: {
    contrastText: '#FFFFFF',
    dark: '#0095C5',
    light: '#0095C5',
    main: '#0095C5',
  },
  inputBox: {
    backgroundColor: '#384D58',
    errorBackgroundColor: '#573D3D',
  },
  selectBox: {
    control: {
      backgroundColor: '#384D58',
    },
    option: {
      backgroundColor: '#384D58',
      selectedBackgroundColor: '#334047',
      focusBackgroundColor: '#35464f',
    },
  },
} as PaletteOptions;
