import {useEffect, useState} from 'react';

import {sendGetRequest, sendPostRequest, URLs} from '../../../api';
import {Group} from '../../../types/entities/Group';
import {User} from '../../../types';
import GroupUsersTable from '../../../components/group-users/GroupUsersTable';

export type GroupUsersProps = {
  selectedGroup: Group;
  refreshSelectedGroup: boolean;
  handleHttpError: (error: any) => void;
};

export const GroupUsers = ({selectedGroup: group, refreshSelectedGroup: refresh, handleHttpError}: GroupUsersProps) => {
  const [users, setUsers] = useState<Array<User> | undefined>(undefined);

  const getUsersInGroup = () => {
    sendGetRequest(URLs.getUsersInGroup(group.id))
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    getUsersInGroup();
  }, [group, refresh]);

  const onUserUnassign = (groupId: number, userEmail: string) => {
    sendPostRequest(URLs.unassignUserFromGroup, {groupId, userEmail})
      .then((_response) => {
        getUsersInGroup();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center mt-2">
        <GroupUsersTable users={users ?? []} userUnassignCallback={onUserUnassign} group={group} />
      </div>
    </>
  );
};
