import {useState} from 'react';

import {Button, useTheme} from '@mui/material';

import {Color} from '../../../types';
import {Group} from '../../../types/entities/Group';
import {Pattern} from '../../../validation/Pattern';
import {sendPostRequest, URLs} from '../../../api';

export type UserAssignProps = {
  selectedGroup: Group;
  onAssignSuccess: () => void;
  onAssignFail: (error: any) => void;
};

function UserAssign({selectedGroup, onAssignSuccess, onAssignFail}: UserAssignProps) {
  const theme = useTheme();
  const [email, setEmail] = useState<string>('');

  const onUserAssign = (groupId: number) => {
    sendPostRequest(URLs.assignUserToGroup, {groupId, userEmail: email})
      .then((_response) => {
        setEmail('');
        onAssignSuccess();
      })
      .catch((err) => {
        if (err) {
          onAssignFail(err);
        }
      });
  };

  return (
    <div className="flex items-center gap-3">
      <div className="w-80">
        <input
          className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
          placeholder="Enter email..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          autoComplete="off"
        />
      </div>
      <Button
        disabled={!Pattern.emailRegex.test(email)}
        color={Color.SECONDARY}
        variant="contained"
        size="small"
        onClick={() => {
          onUserAssign(selectedGroup.id);
        }}
      >
        Assign User
      </Button>
    </div>
  );
}

export default UserAssign;
