import {useState, useMemo, useEffect, useCallback, useContext} from 'react';
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom';

import {Button, InputLabel, MenuItem, Select, Switch} from '@mui/material';

import AppContext from '../../context/AppContext';
import {sendGetRequest, URLs} from '../../api';
import {Color, Problem} from '../../types';
import {ContestSubmission} from '../../types/entities/ContestSubmission';
import {ContestContextType} from './Contest';
import SubmissionsTable from '../../components/contest-card/submissions-table/SubmissionsTable';

function MySubmissions() {
  const {contest} = useOutletContext<ContestContextType>();
  const {id: contestId, groupContest} = useParams();
  const [submissions, setSubmissions] = useState<Array<ContestSubmission>>([]);
  const [problems, setProblems] = useState<{[id: number]: Problem} | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useContext(AppContext);

  const {offset, limit, problemId} = useMemo(() => {
    const offset = Number(searchParams.get('offset')) || 0;
    const limit = Number(searchParams.get('limit')) || 10;

    const problemId = Number(searchParams.get('problemId')) || undefined;

    return {offset, limit, problemId};
  }, [searchParams]);

  useEffect(() => {
    sendGetRequest(
      `${URLs.getMyContestSubmissions(Number(contestId))}?groupContestId=${groupContest}&userId=${context.user
        ?.id}&${searchParams.toString()}`
    ).then((response) => {
      setSubmissions(response.data.submissions);
      setProblems(response.data.problems);
    });
  }, [contestId, groupContest, searchParams]);

  const prevPage = useCallback(() => {
    searchParams.set('offset', (offset - limit).toString());
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const nextPage = useCallback(() => {
    searchParams.set('offset', (offset + limit).toString());
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const filterByProblem = useCallback(
    (problemId: number) => {
      if (isNaN(problemId)) {
        searchParams.delete('problemId');
      } else {
        searchParams.set('problemId', problemId.toString());
      }

      searchParams.delete('offset');
      setSearchParams(searchParams, {replace: true});
    },
    [searchParams]
  );

  const applyLatestSubmissionsFilter = useCallback(() => {
    const latest = searchParams.get('latest') === 'true';
    searchParams.set('latest', (!latest).toString());
    searchParams.delete('offset');
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const applyDuringContestFilter = useCallback(() => {
    const latest = searchParams.get('duringContest') === 'true';
    searchParams.set('duringContest', (!latest).toString());
    searchParams.delete('offset');
    setSearchParams(searchParams, {replace: true});
  }, [searchParams]);

  const modalRoute = (
    contestId: number,
    groupContestId: string | undefined,
    submissionId: number,
    searchParams: URLSearchParams
  ) => `/contest/${contestId}/${groupContestId}/my-submissions/${submissionId}?${searchParams.toString()}`;

  if (!contest) {
    return <div>Contest not found</div>;
  }

  return (
    <div className="flex flex-col items-center h-full">
      <div className="flex flex-col items-center gap-3 max-h-full w-[90%] overflow-scroll">
        <div className="flex justify-between w-full align-middle">
          <div className="flex gap-4 items-center">
            <div className="flex items-center gap-1">
              <InputLabel id="filter-problem-dropdown-label"> Problem </InputLabel>
              <Select
                label="Filter by Problem"
                labelId="filter-problem-dropdown-label"
                id="filter-problem-dropdown"
                value={searchParams.get('problemId') || 'all'}
                onChange={(e) => {
                  filterByProblem(parseInt(e.target.value, 10));
                }}
              >
                <MenuItem value="all"> All </MenuItem>
                {contest.problems.map((problem) => (
                  <MenuItem value={problem.id}> {problem.title} </MenuItem>
                ))}
              </Select>
            </div>

            <div className="flex items-center gap-1">
              <p style={{marginRight: '-10px'}}> Last Submission </p>
              <Switch
                checked={searchParams.get('latest') === 'true'}
                inputProps={{'aria-label': 'Size switch demo'}}
                onChange={() => applyLatestSubmissionsFilter()}
              />
            </div>

            <div className="flex items-center gap-1">
              <p style={{marginRight: '-10px'}}> During Contest </p>
              <Switch
                checked={searchParams.get('duringContest') === 'true'}
                inputProps={{'aria-label': 'Size switch demo'}}
                onChange={() => applyDuringContestFilter()}
              />
            </div>
          </div>
          <div>
            {problemId && (
              <div className="flex w-full">
                <Button
                  onClick={() => {
                    setSearchParams({}, {replace: true});
                  }}
                  size="small"
                  variant="contained"
                  color={Color.SECONDARY}
                >
                  Back to all submissions
                </Button>
              </div>
            )}
          </div>
        </div>

        <SubmissionsTable
          submissions={submissions}
          problems={problems}
          searchParams={searchParams}
          modalRoute={modalRoute}
          pagination={{prevPage, nextPage, offset, limit}}
          filterByProblem={filterByProblem}
          contest={contest}
          groupContest={groupContest}
        />
      </div>
    </div>
  );
}

export default MySubmissions;
