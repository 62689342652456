import {useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {Button, useTheme} from '@mui/material';

import {URLs, sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest} from '../../../api';
import {UpdateCategoryRequest} from '../../../api/Requests';
import CategoriesTable from './CategoriesTable';
import {Color} from '../../../types';
import {Category} from '../../../types/entities/Category';

// ( Eduard ): To be deleted, than use type from StaffPage.tsx
type CategoriesManagementOutletProps = {
  handleHttpError: (err: any) => void;
};

function CategoriesManagement() {
  const theme = useTheme();

  const {handleHttpError} = useOutletContext<CategoriesManagementOutletProps>();

  const [categories, setCategories] = useState<Array<Category>>([]);
  const [newCategory, setNewCategory] = useState<UpdateCategoryRequest>();

  const getCategories = async () => {
    sendGetRequest(URLs.getAllCategories)
      .then((response) => {
        const categories: Array<Category> = response.data;
        setCategories(categories);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleModifyCategory = (categoryId: number, newData: {title: string}) => {
    sendPutRequest(URLs.updateCategory(categoryId), newData)
      .then(() => {
        getCategories();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const handleDeleteCategory = (categoryId: number) => {
    sendDeleteRequest(URLs.deleteCategory(categoryId))
      .then(() => {
        getCategories();
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const handleCreateCategory = (newCategory: {title: string}) => {
    sendPostRequest(URLs.createCategory, newCategory)
      .then(() => {
        getCategories();
        setNewCategory(undefined);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  return (
    <div>
      <div className="flex flex-row justify-end">
        <div className="flex flex-row w-[420px] gap-3">
          <input
            className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
            placeholder="Category name"
            value={newCategory?.title}
            onChange={(event) => setNewCategory({title: event.target.value})}
          />
          <Button
            disabled={newCategory === undefined || newCategory?.title === ''}
            color={Color.SECONDARY}
            variant="contained"
            size="small"
            onClick={() => {
              if (newCategory) {
                handleCreateCategory(newCategory);
              }
            }}
          >
            Create category
          </Button>
        </div>
      </div>

      <CategoriesTable
        categories={categories}
        onModifyCategory={handleModifyCategory}
        onDeleteCategory={handleDeleteCategory}
      />
    </div>
  );
}

export default CategoriesManagement;
