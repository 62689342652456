import {useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {Link, Outlet, useLocation, useParams, useNavigate} from 'react-router-dom';

import {Typography, IconButton, Tooltip} from '@mui/material';
import {Edit, ArrowBackIosNew} from '@mui/icons-material';

import AppContext from '../../context/AppContext';
import {sendGetRequest, URLs} from '../../api';
import {ContestViewData, ContestViewResponse} from '../../api/responses/ContestViewResponseTypes';
import {Page} from '../../components';

export type ContestPreviewContextType = {
  contest: ContestViewData;
};
export const ContestPreview = () => {
  const context = useContext(AppContext);
  const {id} = useParams();

  const [contest, setContest] = useState<ContestViewData | null>(null);
  const [isPrivileged, setIsPrivileged] = useState(false);

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    sendGetRequest(URLs.getContestTemplate(Number(id)))
      .then((response) => {
        const contestData: ContestViewResponse = response.data.template;
        // TODO: No Enrolled Users ATM
        setContest(contestData);
        setIsPrivileged(true);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            context.dispatchError({errorMessage: err.response.data.error, redirectURL: '/contests'});
          } else {
            context.dispatchError({
              errorMessage: 'There was an error. Please try again later',
              redirectURL: '/contests',
            });
          }
        }
      });
  }, [context.user, id, update]);

  const tabs = useMemo(() => getTabs(), [context.user?.role]);

  return (
    <Page>
      {contest ? (
        <div className="flex items-center">
          <div className="grid grid-cols-12 item-center justify-center rounded-2xl bg-card-main mb-10 min-w-[90vw]">
            <div className="col-span-12 min-h-[85vh] min-w-[90vw]">
              <div className="min-h-[83vh]">
                <div className="px-6 py-4">
                  <div className="flex items-center justify-start">
                    <IconButton onClick={() => navigate(-1)}>
                      {' '}
                      <ArrowBackIosNew sx={{fontSize: 54}} className=" text-white" />{' '}
                    </IconButton>
                    <div className="flex justify-start flex-col ml-3">
                      <Typography variant="h3">{`Previewing: ${contest.title}`}</Typography>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-evenly gap-1 py-3 px-1 text-center h-[100%] bg-primary-main">
                  {tabs.map((tab) =>
                    !tab.disabled ? (
                      <Link key={`${tab.name}`} to={tab.path} replace={true} className="no-underline">
                        <Typography
                          style={{
                            color: location.pathname.includes(tab.path) ? '#ffffff' : '#C5C5C5',
                          }}
                          variant="h6"
                        >
                          {typeof tab.name === 'string' ? tab.name.toUpperCase() : tab.name}
                        </Typography>
                      </Link>
                    ) : (
                      <Tooltip title="Disabled in preview mode">
                        <div className="cursor-not-allowed">
                          <Typography
                            style={{
                              color: '#8C8C8C',
                            }}
                            variant="h6"
                          >
                            {typeof tab.name === 'string' ? tab.name.toUpperCase() : tab.name}
                          </Typography>
                        </div>
                      </Tooltip>
                    )
                  )}
                </div>

                <div className="p-5">
                  <Outlet context={{contest, isPrivileged, groupContestId: null}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Page>
  );
};

const getTabs = () => {
  const commonTabs = [
    {
      name: 'Problems',
      path: 'problems',
    },
    {
      name: 'Details',
      path: 'details',
    },
    {
      name: 'Leaderboard',
      path: '',
      disabled: true,
    },
    {
      name: 'Statistics',
      path: '',
      disabled: true,
    },
    {
      name: 'Submissions',
      path: '',
      disabled: true,
    },
  ];

  return commonTabs;
};
