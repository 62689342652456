import {useContext} from 'react';

import AppContext from '../../../context/AppContext';
import {ButtonSize as Size, Color, EnrollmentStatus, Role} from '../../../types';
import {sendPostRequest, URLs} from '../../../api';
import {NonParticipantV2Props} from '../../../components/contests-grid/card/Card.props';
import {Button} from '../../../components';

export const NonParticipantV2 = (props: NonParticipantV2Props) => {
  const {dispatchError, user} = useContext(AppContext);

  const handleSubmit = () => {
    sendPostRequest(URLs.enrollToContestNew(Number(props.contest.id)))
      .then((response) => {
        if (response.data.status !== 'Failed') {
          props.updateStatus(EnrollmentStatus.ENROLLED, props.contest);
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error, redirectURL: '/'});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later', redirectURL: '/'});
          }
        }
      });
  };

  const disabled =
    new Date(props.contest.contest_start_date) > new Date(props.currTime) &&
    !(user!.role === Role.TEACHER || user!.role === Role.AUTHOR_TEACHER);

  return (
    <Button
      className="mt-10"
      color={Color.SECONDARY}
      label="Join"
      onClick={handleSubmit}
      size={Size.SMALL}
      variant={disabled ? 'outlined' : 'contained'}
      disabled={disabled}
    />
  );
};
