import {ReactNode, useContext} from 'react';

import {Typography} from '@mui/material';

import AppContext from '../../context/AppContext';
import {RedirectModal} from '../../components';
import {Color} from '../../types';

export type WrapperComponentProps = {
  children: ReactNode;
};

export function ErrorWrapper({children}: WrapperComponentProps) {
  const context = useContext(AppContext);

  return (
    <>
      {children}

      {context.modalError.isErrorEnabled && (
        <RedirectModal
          buttonLabel={context.modalError.buttonText ?? 'Go back'}
          buttonColor={Color.PRIMARY}
          redirectUrl={context.modalError.redirectURL}
          hasCloseIconButton={context.modalError.hasCloseIconButton}
          children={[
            <Typography variant="h2">Error</Typography>,
            <Typography variant="h4">{String(context.modalError.message)}</Typography>,
          ]}
        />
      )}
    </>
  );
}

export default ErrorWrapper;
