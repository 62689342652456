export type RuleProps = {
  message: string;
};

export abstract class Rule {
  protected ifError(value: boolean, message: string) {
    return value ? false : message;
  }

  public abstract validate(value: string, props: RuleProps): false | string;
}
