import {useState} from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {Delete as DeleteIcon, Edit as EditIcon, DoneAll as DoneAllIcon, Clear as ClearIcon} from '@mui/icons-material';

import {UpdateCategoryRequest} from '../../../api/Requests';
import {formatDate} from '../../../utils/date-utils';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import {Category} from '../../../types/entities/Category';

type CategoriesTableProps = {
  categories: Array<Category>;
  onModifyCategory: (categoryId: number, newData: {title: string}) => void;
  onDeleteCategory: (categoryId: number) => void;
};

function CategoriesTable({categories, onModifyCategory, onDeleteCategory}: CategoriesTableProps) {
  const theme = useTheme();

  const [editedCategory, setEditedCategory] = useState<{id: number; category: UpdateCategoryRequest}>();
  const [categoryToDelete, setCategoryToDelete] = useState<Category>();

  return (
    <div className="mt-6">
      <TableContainer className="bg-background-light">
        <Table
          sx={{
            minWidth: 800,
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTableCell-root': {
              borderColor: theme.palette.card.light,
              color: theme.palette.card.contrastText,
            },
            backgroundColor: theme.palette.card.dark,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell> No. </TableCell>
              <TableCell> Name </TableCell>
              <TableCell> Created at </TableCell>
              <TableCell> Updated at </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody style={{borderColor: theme.palette.card.dark}}>
            {categories.map((category, index) => (
              <TableRow key={category.id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>
                  {category.id === editedCategory?.id ? (
                    <input
                      className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                      value={editedCategory.category.title}
                      onChange={(event) =>
                        setEditedCategory((prev) => {
                          if (prev) {
                            return {...prev, category: {...prev.category, title: event.target.value}};
                          }
                          return prev;
                        })
                      }
                    />
                  ) : (
                    category.title
                  )}
                </TableCell>
                <TableCell>{formatDate(category.created_at.toString())}</TableCell>
                <TableCell>{formatDate(category.updated_at.toString())}</TableCell>
                <TableCell align="center">
                  {category.id === editedCategory?.id ? (
                    <div>
                      <IconButton
                        disabled={editedCategory.category.title === ''}
                        onClick={() => {
                          onModifyCategory(category.id, editedCategory.category);
                          setEditedCategory(undefined);
                        }}
                        sx={{color: 'green'}}
                      >
                        <DoneAllIcon />
                      </IconButton>

                      <IconButton onClick={() => setEditedCategory(undefined)} sx={{color: 'white'}}>
                        <ClearIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton
                      onClick={() =>
                        setEditedCategory({
                          id: category.id,
                          category: {title: category.title},
                        })
                      }
                      sx={{color: 'white'}}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => setCategoryToDelete(category)} color="warning">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {categories.length === 0 && (
          <Typography className="p-5" align="center" variant="h5">
            No categories were found, you can start by creating a new category.
          </Typography>
        )}
      </TableContainer>
      {categoryToDelete && (
        <ConfirmationModal
          message="Are you sure that you want to delete this category? All problems that are assigned to this category will be unassigned."
          onClose={() => setCategoryToDelete(undefined)}
          onAccept={() => {
            onDeleteCategory(categoryToDelete.id);
            setCategoryToDelete(undefined);
          }}
        />
      )}
    </div>
  );
}

export default CategoriesTable;
