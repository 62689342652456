import React, {useState, useReducer, useEffect, Children, useRef} from 'react';

import {Grid} from '@mui/material/';

import {ButtonSize as Size, Color, FormProps, InputProps} from '../../types';
import {Validator} from '../../validation/Validator';
import {Button} from '../button/Button';
import {Input} from './input/Input';
import PasswordInput from './PasswordInput';
import {Textarea} from './textarea/Textarea';
import {updateProperty} from '../../utils';
import {Toggle} from '../toggle/Toggle';
import {Dropdown, MultiDropdown, UnmanagedDropdown} from './dropdown/Dropdown';
import {CodeEditor} from '../code-editor/CodeEditor';
import {Test} from './test/Test';
import {DatePicker} from './date-picker/DatePicker';
import {Pair} from './pair/Pair';
import {Markdown} from './markdown/Markdown';
import {Checkbox} from './checkbox/Checkbox';
import ProblemLanguages from './problem-languages/ProblemLanguages';
import {InfoText} from './info-text/InfoText';
import TransferList from './transfer-list/TransferList';

const createInput = (props: InputProps & {type: string}) => {
  const {type, ...inputProps} = props;
  switch (type) {
    case 'input':
      return <Input {...inputProps} />;
    case 'password-input':
      return <PasswordInput {...inputProps} />;
    case 'textarea':
      return <Textarea {...inputProps} />;
    case 'markdown':
      return <Markdown {...inputProps} />;
    case 'toggle':
      return <Toggle {...inputProps} />;
    case 'dropdown':
      return <Dropdown {...inputProps} />;
    case 'unmanaged-dropdown':
      return <UnmanagedDropdown {...inputProps} />;
    case 'code':
      return <CodeEditor code={inputProps.value} {...inputProps} />;
    case 'test':
      return <Test {...inputProps} />;
    case 'date':
      return <DatePicker {...inputProps} />;
    case 'problem':
      //return <Typography variant="subtitle1">{'TEMPORARY UNAVAILABLE. TODO: DISCUSS WITH EMIL & SERBAN'}</Typography>
      return <Pair {...inputProps} />;
    case 'multiselect':
      return <MultiDropdown {...inputProps} isMulti={true} />;
    case 'checkbox':
      return <Checkbox {...inputProps} />;

    case 'problem-languages':
      return <ProblemLanguages languages={inputProps.languages} {...inputProps} />;
    case 'transfer-list':
      return <TransferList {...inputProps} />;
    case 'info-text':
      return <InfoText {...inputProps} />;
    default:
      return <></>;
  }
};

export const getTimezone = () => {
  const utc = `UTC ${new Date().toString().split(' ')[5].split('GMT')[1]}`;
  return `${utc.slice(0, utc.length - 2)}:${utc.slice(utc.length - 2)}`;
};

export const initialValues = (props: FormProps) => {
  const allInputs = props.inputs.reduce((a, b) => a.concat(b), []);
  return allInputs
    .filter((input: any) => input.value !== undefined)
    .map((input: any) => ({[input.id]: input.value}))
    .reduce((a, b) => Object.assign({}, a, b), {});
};

export const Form = (props: FormProps) => {
  const [data, setData] = useState<Record<string, any>>(initialValues(props));
  const [errors, setErrors] = useState<Record<string, any>>({});

  // Modifications counter is counting how many times did the onChange callback was called
  // This is used to determine if an input was edited/changed
  // NOTE: it might create bugs if the inputs are calling the form's onChange callback AT MOUNT (FOR FIX CHECK <Test /> COMPONENT)
  const [modifCounter, setModifCounter] = useState<number>(-1);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const commonProps = {
    className: props.inputClass,
    fullWidth: props.fullWidth,
  };

  const allIds = props.inputs.flat().map((input) => input.id);
  const completeProps = props.inputs.map((row) =>
    row.map(({value, ...inputProps}) => {
      const validate = () => {
        setErrors(updateProperty(errors, inputProps.id, Validator.validate(data[inputProps.id], inputProps.rules)));
        if (
          allIds.indexOf('confirmation_password') >= 0 &&
          ['password', 'confirmation_password'].indexOf(inputProps.id) >= 0
        ) {
          const newError =
            data['password'] !== data['confirmation_password'] ? 'Parola de confirmare nu coincide' : false;
          setErrors(updateProperty(errors, 'confirmation_password', newError));
        }
        forceUpdate();
      };
      return {
        ...commonProps,
        ...inputProps,
        value: data[inputProps.id] ?? value,
        error: inputProps.error ?? errors[inputProps.id],
        validate: validate,
        onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, newValue: any) => {
          let value = event && event.target ? event.target.value : newValue;
          if (inputProps.type === 'code' || inputProps.type === 'markdown') {
            value = event;
          }
          if (inputProps.type === 'dropdown') {
            value = (event as unknown as {label: string; value: string}).value;
          }
          if (inputProps.type === 'multiselect') {
            value = (event as unknown as Array<{label: string; value: string}>).map((option) => option.value);
          }
          if (inputProps.type === 'checkbox') {
            value = (event.target as any).checked || false;
          }
          if (inputProps.onChange) {
            inputProps.onChange(value);
          }
          setData(updateProperty(data, inputProps.id, value));
          validate();
          setModifCounter((prev) => prev + 1);
        },
      };
    })
  );

  const handlesubmit = (event: React.FormEvent) => {
    event.preventDefault();
    completeProps.forEach((column) => column.forEach((input) => input.validate()));
    const noErrors = Object.values(errors).every((x) => [null, false, undefined, ''].indexOf(x) >= 0);
    if (noErrors === true) {
      props.button.onClick(data);
    }
  };

  useEffect(() => {
    if (modifCounter === 1 && props.onFirstFieldChange) {
      props.onFirstFieldChange();
    }
  }, [modifCounter]);

  useEffect(() => {
    props.inputs.forEach((row) => {
      row.forEach((input) => {
        if (input.value) {
          setData(updateProperty(data, input.id, input.value));
          forceUpdate();
        }
      });
    });
  }, [data]);

  return (
    <form onSubmit={handlesubmit}>
      <div className={props.formClass}>
        <Grid container spacing={1}>
          {Children.toArray(
            completeProps.map((row) => {
              // Split the rows in multiple columns by default from xs if not specified otherwise
              const splitRowsFrom = props.splitRowsFrom ?? 'xs';
              const boundaries = Object.assign(
                {},
                splitRowsFrom === 'xs' ? null : {xs: 12},
                splitRowsFrom === undefined ? null : {[splitRowsFrom]: 12 / row.length}
              );
              return (
                <Grid item xs={12}>
                  <Grid container spacing={{xs: 1, [splitRowsFrom]: 2}}>
                    {Children.toArray(
                      row.map((allProps) => (
                        <Grid item {...(allProps.xs ? {xs: allProps.xs} : boundaries)}>
                          {createInput(allProps)}
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              );
            })
          )}
        </Grid>
      </div>
      <Button
        className={props.button.className}
        color={Color.PRIMARY}
        fullWidth={true}
        label={props.button.label}
        size={Size.MEDIUM}
        type="submit"
      />
    </form>
  );
};
