import {createTheme} from '@mui/material/styles';

import {components} from './Components';
import {palette} from './Palette';
import {typography} from './Typography';

export const theme = createTheme({
  components,
  palette,
  typography,
});
