import {ReactNode} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import {Contact, Home, Login, Register, Problem, Problems, ResetPassword, Contest, Profile} from '../pages';
import {TrainerPage} from '../pages/staff/trainer/TrainerPage';
import {NotFound} from '../pages/not-found/NotFound';
import {Contests} from '../pages/contests/Contests';
import {SetPassword} from '../pages/auth/set-password/SetPassword';
import {ContestSubmissionView} from '../pages/contest/SubmissionView';
import {ContestDetails} from '../pages/contest/ContestDetails';
import {ContestLeaderboard} from '../pages/contest/ContestLeaderboard';
import {ContestProblems} from '../pages/contest/ContestProblems';
import ProfileHome from '../pages/profile/ProfileHome';
import ProfileAccount from '../pages/profile/ProfileAccount';
import ProfileSettings from '../pages/profile/ProfileSettings';
import MySubmissions from '../pages/contest/MySubmissions';
import {Role, Route as RouteData} from '../types';
import {ContestSubmissions} from '../components/contest-card/ContestSubmissions';
import {Statistics} from '../components/contest-card/Statistics';
import {StaffPage} from '../pages/staff/StaffPage';
import {StudentsManagement} from '../pages/staff/students-management/StudentsManagement';
import {GroupsManagement} from '../pages/staff/groups-management/GroupsManagement';
import {PendingUsersView} from '../pages/staff/users-management/PendingUsersView';
import UsersManagement from '../pages/staff/users-management/UsersManagement';
import ActiveUsersView from '../pages/staff/users-management/active-users/ActiveUsersView';
import CategoriesManagement from '../pages/staff/categories-managament/CategoriesManagament';
import ImportUsersView from '../pages/staff/import-users/ImportUsersView';
import ArchivedUsersView from '../pages/staff/users-management/archived-users/ArchivedUsersView';
import {ContestTemplates} from '../pages/staff/contest-templates/ContestTemplates';
import AuthorsManagement from '../pages/staff/authors/AuthorsManagement';
import {ContestPreview} from '../pages/contest-preview/ContestPreview';
import {ContestPreviewProblems} from '../pages/contest-preview/ContestPreviewProblems';
import CoursesManagement from '../pages/staff/courses-management/CoursesManagement';
import {VerifyAccount} from '../pages/auth/verify-account/VerifyAccount';
import ModulesManagement from '../pages/staff/modules-management/ModulesManagement';

export default function Router(props: {children?: Array<ReactNode>; role?: Role}) {
  const commonRoutes = [new RouteData('contact', '/contact', <Contact />), new RouteData('home', '/', <Home />)];

  const signedOutRoutes = [
    new RouteData('login', '/login', <Login />),
    new RouteData('register', '/register', <Register />),
    new RouteData('reset-password', '/reset-password', <ResetPassword />),
    new RouteData('set-password', '/set-password', <SetPassword />),
    new RouteData('verify-account', '/verify-account', <VerifyAccount />),
  ];

  const signedInRoutes = [
    new RouteData('contests', '/contests', <Contests />),
    new RouteData('problem', '/problem/:problemId/:contestId/:groupContestId', <Problem />),
    new RouteData('problem', '/problem/:problemId', <Problem />),
  ];

  const notFoundRoutes = [
    new RouteData('404-0', '/404', <NotFound />),
    new RouteData('404-1', '/*', <Navigate replace to="/404" />),
  ];

  const allRoutes = [
    ...commonRoutes,
    ...(props.role === undefined ? signedOutRoutes : []),
    ...(props.role !== undefined ? signedInRoutes : []),
    ...notFoundRoutes,
  ];
  return (
    <>
      <Routes>
        {allRoutes.map((route: RouteData) => (
          <Route key={route.key} element={route.element} path={route.path} />
        ))}

        <Route key="contest" element={<Contest />} path="/contest/:id/:groupContest">
          <Route key="contest-problems" element={<ContestProblems />} path="problems" />
          <Route key="contest-details" element={<ContestDetails />} path="details" />
          <Route key="contest-leaderboard" element={<ContestLeaderboard />} path="leaderboard" />
          <Route key="contest-statistics" element={<Statistics />} path="statistics" />
          <Route key="contest-submissions" element={<ContestSubmissions />} path="submissions">
            <Route key="contest-submission" element={<ContestSubmissionView />} path=":submissionId" />
          </Route>
          <Route key="contest-my-submissions" element={<MySubmissions />} path="my-submissions">
            <Route key="contest-my-submission" element={<ContestSubmissionView />} path=":submissionId" />
          </Route>
        </Route>

        <Route key="profile" element={<Profile />} path="profile">
          <Route key="profile-home" element={<ProfileHome />} index />
          <Route key="profile-account" element={<ProfileAccount />} path="account" />
          <Route key="profile-settings" element={<ProfileSettings />} path="settings" />
        </Route>

        {props.role === Role.ADMIN && (
          <>
            <Route key="contest" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-problems" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
            </Route>
            <Route key="staff" element={<StaffPage />} path="staff">
              <Route key="staff-users" element={<UsersManagement />} path="users">
                <Route key="staff-students-active" element={<ActiveUsersView />} index />
                <Route key="staff-students-pending" element={<PendingUsersView />} path="pending" />
                <Route key="staff-students-archived" element={<ArchivedUsersView />} path="archived" />
              </Route>
              <Route key="staff-students" element={<StudentsManagement />} path="account" />
              <Route key="staff-groups" element={<GroupsManagement />} path="settings" />
              <Route key="staff-import-users" element={<ImportUsersView />} path="import-users" />
              <Route key="staff-curriculum" element={<TrainerPage />} path="curriculum" />
              <Route key="staff-courses" element={<CoursesManagement />} path="courses" />
              <Route key="staff-modules" element={<ModulesManagement />} path="modules" />
              <Route key="staff-categories" element={<CategoriesManagement />} path="categories" />
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />
              <Route key="staff-authors" element={<AuthorsManagement />} path="authors" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.role === Role.CREATOR && (
          <>
            <Route key="contest" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-problems" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
            </Route>
            <Route key="staff" element={<StaffPage />} path="staff">
              <Route key="staff-categories" element={<CategoriesManagement />} path="categories" />
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.role === Role.TEACHER && (
          <>
            <Route key="staff" element={<StaffPage />} path="staff">
              <Route key="staff-curriculum" element={<TrainerPage />} path="curriculum" />
              <Route key="staff-students" element={<StudentsManagement />} path="account" />
            </Route>
          </>
        )}
        {props.role === Role.AUTHOR && (
          <>
            <Route key="contest" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-problems" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="staff" element={<StaffPage />} path="staff">
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.role === Role.COURSE_ADMIN && (
          <>
            <Route key="contest" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-problems" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="staff" element={<StaffPage />} path="staff">
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />
              <Route key="staff-students" element={<StudentsManagement />} path="account" />
              <Route key="staff-groups" element={<GroupsManagement />} path="settings" />
              <Route key="staff-curriculum" element={<TrainerPage />} path="curriculum" />
              <Route key="staff-courses" element={<CoursesManagement />} path="courses" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.role === Role.AUTHOR_TEACHER && (
          <>
            <Route key="contest" element={<ContestPreview />} path="/contest/preview/:id">
              <Route key="contest-problems" element={<ContestPreviewProblems />} path="problems" />
              <Route key="contest-details" element={<ContestDetails />} path="details" />
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />
            </Route>
            <Route key="staff" element={<StaffPage />} path="staff">
              <Route key="staff-contest-templates" element={<ContestTemplates />} path="contest-templates" />\
              <Route key="staff-curriculum" element={<TrainerPage />} path="curriculum" />
              <Route key="staff-students" element={<StudentsManagement />} path="account" />
            </Route>
            <Route key="problems" element={<Problems />} path="problems" />
          </>
        )}
        {props.children}
      </Routes>
    </>
  );
}
