import {ContestsViewContest} from '../../../api/responses/ContestsViewResponseTypes';
import {EnrollmentStatus, Role, User} from '../../../types';
import {NonParticipantV2} from './NonParticipantV2';
import {ParticipantV2} from './ParticipantV2';

export const getStatus = (
  index: number,
  contest: ContestsViewContest,
  user: User | undefined,
  currTime: Date,
  updateStatus: (newStatus: EnrollmentStatus, contest: ContestsViewContest) => void
) => {
  if (user === undefined) {
    // TODO1: handle redirect if the user is not authenticated
    return <></>;
  } else if (contest.created_by === user.id || user.role === Role.ADMIN || user.role === Role.COURSE_ADMIN) {
    return <ParticipantV2 contest={contest} index={index} />;
  } else if (
    (new Date(currTime).getTime() < new Date(contest.start_date).getTime() ||
      new Date(currTime).getTime() > new Date(contest.end_date).getTime()) &&
    contest.hard_deadline &&
    user.role !== Role.TEACHER &&
    user.role !== Role.AUTHOR_TEACHER
  ) {
    return <></>;
  } else if (!user.enrolledContests.includes(contest.id)) {
    return (
      <NonParticipantV2 contest={contest} index={index} updateStatus={updateStatus} currTime={new Date(currTime)} />
    );
  } else {
    return <ParticipantV2 contest={contest} index={index} />;
  }
};
