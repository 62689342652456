import {useContext} from 'react';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';

import {Password} from '@mui/icons-material';
import {styled, Typography} from '@mui/material';

import AppContext from '../../../context/AppContext';
import {Form} from '../../../components';
import {breakpoints} from '../../../types/grid/Breakpoints';
import {sendPostRequest, URLs} from '../../../api';

const Container = styled('div')(() => ({
  boxSizing: 'border-box',
  padding: '7% 10% 7% 10%',
  textAlign: 'center',
  width: '100%',

  [breakpoints.up_to_md.css]: {
    padding: '10%',
  },
}));

export const ResetPasswordForm = () => {
  const {dispatchError} = useContext(AppContext);
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = (data: any) => {
    sendPostRequest(URLs.resetPassword2, {...data, token: search.get('token')})
      .then((response) => {
        if (response.status === 200) {
          navigate('/login');
        }
      })
      .catch((err) => {
        if (err) {
          dispatchError({
            errorMessage:
              "It seems your reset link has expired. Please request a new one, you'll be redirected to the password reset page.",
            redirectURL: '/reset-password',
            buttonText: 'Reset Password',
            hasCloseIconButton: true,
          });
        }
      });
  };

  return (
    <Container>
      <Typography sx={{paddingBottom: '30px'}} variant="h3">
        Fill in your new password
      </Typography>
      <hr />
      <Form
        button={{
          className: 'mt-10 mb-10',
          label: 'Set new password',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputClass="mb-10"
        inputs={[
          [
            {
              id: 'password',
              placeholder: 'Password',
              rules: {},
              startAdornment: <Password />,
              type: 'password-input',
            },
          ],
          [
            {
              id: 'confirm',
              placeholder: 'Confirm Password',
              rules: {},
              startAdornment: <Password />,
              type: 'password-input',
            },
          ],
        ]}
      />
    </Container>
  );
};
