import {Close, DeleteForever} from '@mui/icons-material';
import {Button, Modal, Typography} from '@mui/material';

type EradicateStudentModalProps = {
  closeCallback: () => void;
  eradicateStudentCallback: () => void;
};

function EradicateStudentModal({closeCallback, eradicateStudentCallback}: EradicateStudentModalProps) {
  return (
    <Modal
      open={true}
      onClose={() => closeCallback()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="top-2/4 left-2/4 w-[40%] h-fit p-4 absolute translate-x-[-50%] translate-y-[-50%] bg-background-default">
        <div className="flex flex-col justify-center items-center gap-3 p-2">
          <Typography variant="h3">
            <span className="text-red-600">Completely delete Student</span>
          </Typography>

          <Typography className="pt-6" align="center" variant="body1">
            You&apos;re about to begin the process of deleting a student&apos;s account, which will also delete all data
            associated with that student, including submissions, enrollments, and other student-specific information.
          </Typography>

          <Typography variant="h6">ARE YOU SURE YOU WANT TO CONTINUE?</Typography>

          <div className="flex flex-row justify-center gap-4 mt-6 w-full">
            <Button
              variant="contained"
              color="warning"
              className="mr-4"
              startIcon={<DeleteForever />}
              onClick={() => eradicateStudentCallback()}
            >
              DELETE
            </Button>

            <Button variant="contained" color="gridSecondary" startIcon={<Close />} onClick={() => closeCallback()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EradicateStudentModal;
