/*
 *                           (minX, maxY)---------------- (maxX, maxY)
 *                               |      rotated_P1            |           minX = min(rotated_P1.x, rotated_P2.x, ...)
 *                               |    .             .         |           maxX = max(rotated_P1.x, rotated_P2.x, ...)
 *      P1 ....... P2            |   .               rotated_P2           new_width = maxX - minX
 *      .           .   =>       |  .                 .       |
 *      .           .       rotated_P4              .         |           minY = min(rotated_P1.y, rotated_P2.y, ...)
 *      P3 ....... P4            |    .           .           |           maxY = max(rotated_P1.y, rotated_P2.y, ...)
 *                               |      rotated_P3            |           new_height = maxY - minY
 *                           (minX, minY)---------------- (maxX, minY)
 */

function rotatePoint(x: number, y: number, sin: number, cos: number) {
  return {
    x: x * cos - y * sin,
    y: x * sin + y * cos,
  };
}

export function rotate(width: number, height?: number, rotationAngle?: number) {
  if (!rotationAngle || !height) {
    return {
      height: height,
      width: width,
    };
  }

  const sin = Math.sin(rotationAngle);
  const cos = Math.cos(rotationAngle);

  const rotated_points = [
    rotatePoint(0, 0, sin, cos),
    rotatePoint(0, width, sin, cos),
    rotatePoint(height, 0, sin, cos),
    rotatePoint(height, width, sin, cos),
  ];

  const minX = Math.min(...rotated_points.map((point) => point.x));
  const maxX = Math.max(...rotated_points.map((point) => point.x));

  const minY = Math.min(...rotated_points.map((point) => point.y));
  const maxY = Math.max(...rotated_points.map((point) => point.y));

  return {
    height: maxY - minY,
    width: maxX - minX,
  };
}
