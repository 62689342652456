import {Typography} from '@mui/material';

export type CompilationErrorBoxProps = {
  error: string;
};

export const CompilationErrorBox = (props: CompilationErrorBoxProps) => (
  <div className="flex flex-wrap border border-solid rounded-lg p-4 min-h-[256px] overflow-y-scroll">
    <Typography fontWeight="bold" variant="subtitle1">
      Compilation error
    </Typography>
    <Typography variant="subtitle1">{props.error}</Typography>
  </div>
);
