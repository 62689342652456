import {useNavigate} from 'react-router';

import {ButtonSize as Size, Color} from '../../../types';
import {Button} from '../../../components';
import {ContestCardProps} from '../../../components/contests-grid/card/Card.props';

export const ParticipantV2 = (props: ContestCardProps) => {
  const navigate = useNavigate();
  return (
    <Button
      className="mt-10"
      color={Color.PRIMARY}
      onClick={() => navigate(`/contest/${props.contest.id}/${props.contest.group_contest_id}/problems`)}
      label="Show"
      size={Size.SMALL}
      variant="outlined"
    />
  );
};
