import {Typography} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

import {InputProps as Props} from '../../../types/';
import {Error} from '../error/Error';

export const Markdown = (props: Props) => {
  const error = props.error ?? false;
  return (
    <div className={props.className}>
      {props.placeholder && <Typography fontWeight="bold">{props.placeholder}</Typography>}
      <MDEditor
        value={props.value}
        onChange={(value) => {
          props.onChange && props.onChange(value);
        }}
        style={{
          listStyle: 'disc !important',
        }}
      />
      {error !== false && <Error id={props.id} message={error} />}
    </div>
  );
};
