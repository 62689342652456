import {useContext, useState} from 'react';

import {Button, Checkbox, Typography} from '@mui/material';

import AppContext from '../../context/AppContext';
import {UserSettings} from '../../types/entities/UserSettings';

type LeaderboardVisibilityToggleProps = {
  saveCallback: (partialNewUserSettings: UserSettings) => Promise<boolean>;
};

function LeaderboardVisibilityToggle({saveCallback}: LeaderboardVisibilityToggleProps) {
  const context = useContext(AppContext);

  const [isChecked, setIsChecked] = useState<boolean>(context.user?.settings.appearInLeaderboard || false);
  const [isSavedSuccessfully, setIsSavedSuccessfully] = useState<boolean>(false);

  const toggleIsChecked = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className="flex flex-row items-center">
        <Checkbox checked={isChecked} onChange={toggleIsChecked} name={'leaderboard-visibility-toggle'} size="medium" />
        <Typography variant="subtitle1">{'Appear in leaderboard'}</Typography>
      </div>
      <div className="flex flex-row justify-between items-center w-full">
        <div>{isSavedSuccessfully && <Typography variant="body2">Saved successfully!</Typography>}</div>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          onClick={async () => {
            setIsSavedSuccessfully(await saveCallback({appearInLeaderboard: isChecked}));
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default LeaderboardVisibilityToggle;
