import {DiffEditor} from '@monaco-editor/react';

export type DiffViewerProps = {
  output: string;
  expected: string;
  titles?: {outputTitle: string; expectedTitle: string};
};

export const DiffViewer = ({output, expected, titles}: DiffViewerProps) => (
  <div className="w-full">
    {titles && (
      <div className="grid grid-cols-2 bg-[#161616] w-full">
        <div className="col-span-1 ml-7 py-2">{titles.outputTitle}</div>
        <div className="col-span-1 ml-7 py-2">{titles.expectedTitle}</div>
      </div>
    )}
    <DiffEditor
      original={output}
      modified={expected}
      theme="vs-dark"
      options={{
        ignoreTrimWhitespace: false,
        renderWhitespace: 'all',
        readOnly: true,
        minimap: {
          enabled: false,
        },
        renderOverviewRuler: false,
      }}
      className="min-w-[300px] min-h-[300px] "
    />
  </div>
);
