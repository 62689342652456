import {Typography} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import {Result} from '../../../types';

export type ResultsBoxProps = {
  results: Result[];
  onClick: (index: number) => void;
  selectedTest: number | undefined;
};

export const ResultsBox = ({selectedTest, results, onClick}: ResultsBoxProps) => (
  <div className="flex flex-wrap p-2 my-1 rounded border border-gray-500">
    {results.map((result, resId) => (
      <div key={resId} className="flex justify-around w-[33%]" id={`result-box-${resId}`}>
        <div className="flex flex-row gap-4">
          <Typography
            className="cursor-pointer"
            onClick={() => onClick(resId)}
            sx={{
              ':hover': {color: '#F1F1F1'},
              color: `${resId === selectedTest ? '#FFFFFF' : '#808080'}`,
            }}
          >
            {`Test ${resId + 1}`}
          </Typography>
          {result.status === 'PASSED' ? <DoneIcon color="secondary" /> : <CloseIcon color="error" />}
        </div>
      </div>
    ))}
  </div>
);
