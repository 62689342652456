import {useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {useTheme} from '@mui/material/styles';
import {Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Typography, IconButton} from '@mui/material/';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import {sendGetRequest, sendDeleteRequest, sendPostRequest, URLs} from '../../../api';
import {User} from '../../../types';
import PendingUserDeleteModal from '../../../components/modal/ConfirmationModal';
import {rolesMapping} from '../../../utils';
import {UsersManagementOutletProps} from './UsersManagement';

export const PendingUsersView = () => {
  const theme = useTheme();

  const {handleHttpError}: UsersManagementOutletProps = useOutletContext();

  const [pendingUsers, setPendingUsers] = useState<Array<User>>([]);

  const [selectedUserID, setSelectedUserID] = useState<number | undefined>(undefined);

  const [showEditingModal, setShowEditingModal] = useState<boolean>(false);

  const onActivatePressed: (userId: number) => void = (userId: number) => {
    sendPostRequest(URLs.changeUserStatus, {userId, status: 'active'})
      .then((response) => {
        const user = response.data;
        setPendingUsers(pendingUsers.filter((pendingUser) => pendingUser.email !== user.email));
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const handleUserDeletion = () => {
    sendDeleteRequest(URLs.updateUser, {userId: selectedUserID})
      .then(() => {
        setPendingUsers(pendingUsers.filter((deletedUser) => deletedUser.id !== selectedUserID));
        setSelectedUserID(undefined);
        setShowEditingModal(false);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const showDeleteModal = (userId: number) => {
    setShowEditingModal(true);
    setSelectedUserID(userId);
  };

  useEffect(() => {
    sendGetRequest(URLs.pendingUsers)
      .then((response) => {
        setPendingUsers(response.data.map((user: any) => ({...user, role: rolesMapping(user.role)})));
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        {pendingUsers && (
          <TableContainer className="bg-background-light">
            <Table
              sx={{
                '& .MuiTableRow-root:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiTableCell-root': {
                  borderColor: theme.palette.card.light,
                  color: theme.palette.card.contrastText,
                },
                backgroundColor: theme.palette.card.dark,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      <div className="flex flex-col items-start">
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                        <span className="text-gray-400">{`${user.nickName}`}</span>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => onActivatePressed(user.id)} sx={{color: theme.palette.primary.main}}>
                        <DoneIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => showDeleteModal(user.id)} sx={{color: theme.palette.common.white}}>
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {pendingUsers && pendingUsers.length === 0 && (
              <Typography className="p-5" align="center" variant="h5">
                No pending users
              </Typography>
            )}
          </TableContainer>
        )}
      </div>

      {showEditingModal && (
        <PendingUserDeleteModal
          message={"This user's data will be lost, are you sure?"}
          onClose={() => setShowEditingModal(false)}
          onAccept={() => handleUserDeletion()}
        />
      )}
    </>
  );
};
