import React from 'react';

import {Grid, Typography} from '@mui/material/';
import {styled, useTheme} from '@mui/material/styles';

import {Button, Ellipse, Page} from '../../components';
import {ButtonSize, Color, EllipseSize, Position} from '../../types';

const Description = styled(Grid)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  gridRowGap: '5vh',
  justifyContent: 'center',
  padding: '16vh 3vw 6vh 3vw',
  textAlign: 'center',
}));

/**
 * Home page
 */
export const Home = () => {
  const theme = useTheme();
  return (
    <Page>
      <Description container direction="column">
        <Typography variant="h1">
          Real-time, in-depth code evaluation platform for Computer Science Assignments
        </Typography>
        <Typography variant="h5">Designed for Java Backend and Java Full Stack nano-degree programs.</Typography>
      </Description>
      <Button color={Color.PRIMARY} label="Get started" link="/login" size={ButtonSize.MEDIUM} />
      <Ellipse
        backgroundColor={theme.palette.secondary.main}
        bounds={{
          maxHeight: 235,
          paddingTop: 50,
          position: Position.RELATIVE,
        }}
        height={500}
        fitParentWidth={true}
        size={EllipseSize.TOP_HALF}
        width={1000}
      />
    </Page>
  );
};
