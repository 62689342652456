import {useOutletContext} from 'react-router-dom';

import {ContestDescription} from '../../components/contest-card/Description';
import {ContestContextType} from './Contest';

export const ContestDetails = () => {
  const {contest} = useOutletContext<ContestContextType>();

  if (!contest) return null;

  return (
    <ContestDescription
      about={contest.about}
      description={contest.description}
      prize={contest.prize}
      rules={contest.rules}
    />
  );
};
