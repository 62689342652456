import {useMemo} from 'react';

import {Table as MuiTable, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography} from '@mui/material';

import {theme} from '../../../types/theme/Theme';
import {formatDate} from '../../../utils/date-utils';
import {contestsPaginationStyle} from '../../../pages/contests/contests-table/contest-table-styles';
import {LeaderboardEntryResponse} from '../../../api/responses/LeaderboardEntiesResponseTypes';
import {LeaderboardPaginationState} from '../../leaderboard/Leaderboard';

type LeaderBoardTableProps = {
  onClick: (userId: number) => (_event: any) => void;
  entriesRows: Array<LeaderboardEntryResponse>;
  setRowsPerPage: (newPerPage: number) => void;
  setPage: (newPage: number) => void;
  totalNoRankings: number;
  state: LeaderboardPaginationState;
};

type LeaderboardEntry = {
  userId: number;
  rank: number;
  user: string;
  score: number;
  lastSubmission: string;
};

export default function LeaderboardTable({
  entriesRows,
  onClick,
  setRowsPerPage,
  setPage,
  totalNoRankings,
  state,
}: LeaderBoardTableProps) {
  const formatedRankigns = useMemo(
    (): Array<LeaderboardEntry> =>
      entriesRows.map((rankEntry) => ({
        userId: rankEntry.user_id,
        rank: rankEntry.ranking,
        user: rankEntry.userName,
        score: rankEntry.points,
        lastSubmission: rankEntry.updated_at ? formatDate(rankEntry.updated_at) : '-',

        // TODO: modify the route to return the number of problems solved/attempted
        // attempted: '',
        // solved: '',
      })),
    [entriesRows]
  );

  return (
    <div className="flex flex-col h-full justify-between content-between px-7 pt-2 pb-0">
      <MuiTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="h6">RANK</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">USER</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6">SCORE</Typography>
            </TableCell>
            <TableCell width="350">
              <Typography variant="h6">LAST SUBMISSION</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formatedRankigns.map((entry: LeaderboardEntry) => (
            <TableRow
              key={entry.userId}
              onClick={onClick(entry.userId)}
              sx={{
                '&:last-child td, &:last-child th': {border: 0},
                cursor: 'pointer',
              }}
            >
              <TableCell align="center" sx={{color: 'white'}}>
                {entry.rank}
              </TableCell>
              <TableCell sx={{color: 'white'}}>{entry.user}</TableCell>
              <TableCell align="center" sx={{color: 'white'}}>
                {entry.score}
              </TableCell>
              <TableCell width="350" sx={{color: 'white'}}>
                {entry.lastSubmission}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      {formatedRankigns.length === 0 && (
        <Typography align="center" variant="h5" sx={{paddingTop: '20px'}}>
          No data to be displayed...
        </Typography>
      )}

      <TablePagination
        sx={contestsPaginationStyle(theme)}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalNoRankings}
        rowsPerPage={state.rowsPerPage}
        page={state.page}
        onPageChange={(event, page) => setPage(page)}
        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
      />
    </div>
  );
}
