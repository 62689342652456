import {useContext, useEffect, useReducer, useState} from 'react';

import {Button, IconButton, Typography} from '@mui/material';
import {AutoAwesome, EmojiEvents, Rule, Info, Close} from '@mui/icons-material';

import AppContext from '../../context/AppContext';
import {NewContestProps as Props} from './NewContest.props';
import {Form as FormComponent} from '..';
import {ModuleType} from '../../types/entities/ModuleType';
import {CreateContestRequest} from '../../api/Requests';
import {URLs, sendGetRequest, sendRequest} from '../../api';
import {languageOptions} from '../../types/entities/Language';
import ConfirmationModal from '../modal/ConfirmationModal';

export const Form = (props: Props) => {
  const {dispatchError} = useContext(AppContext);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [errors] = useState<Record<string, any>>({});

  const [modules, setModules] = useState<Array<ModuleType>>([]);
  const [selectedModule, setSelectedModule] = useState<ModuleType | undefined>();
  const [selectedModuleToBe, setSelectedModuleToBe] = useState<ModuleType | undefined>();

  const [selectedLanguage, setSelectedLanguage] = useState<{value: number; label: string} | null>({
    label: languageOptions.find((language) => language.languageId === props.language_id)?.label || 'All languages',
    value: languageOptions.find((language) => language.languageId === props.language_id)?.languageId || -1,
  });
  const [selectedLanguageIdToBe, setSelectedLanguageIdToBe] = useState<number | null>(null);

  const [isHardDeadline, setIsHardDeadline] = useState<boolean>(props.hard_deadline ?? false); // Not the state per se, but a mirror used to show correct set-timeout value

  const languages = [
    {label: 'All languages', value: -1},
    ...languageOptions.map((language) => ({label: language.label, value: language.languageId})),
  ];

  useEffect(() => {
    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        setModules(modules);
        if (props.moduleId) {
          setSelectedModule(modules.find((module) => module.id === props.moduleId));
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  }, []);

  const handleSubmit = async (data: any) => {
    if (!selectedModule) {
      dispatchError({
        errorMessage: 'Please select a module, this choice is permanent and it cannot be changed afterwards.',
      });
      return;
    }

    const request: CreateContestRequest = {
      title: data.title,
      password: data.password ?? '',
      description: data.description,
      prize: data.prize ?? '',
      rules: data.rules ?? '',
      about: data.about ?? '',
      //scoring: data.scoring ?? '' // TODO: Discuss with Emil / Serban / Cristi
      problems: (data.pairs ?? []).map((pair: any) => pair.problem),
      module_id: selectedModule.id as number,
      hard_deadline: data.hard_deadline,
      contest_language_id: selectedLanguage?.value || -1,
      // submission_timeout: 10, // (EDUARD): FOR NOW, UNUSED ON BACKEND; MIGHT BE USED IN THE FUTURE, TIMEOUT IS ALWAYS 10 SECONDS
    };

    sendRequest(props.method, props.url, request)
      ?.then((response) => {
        if (response) {
          props.onSuccess();
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.cause.details[0]?.message});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  };

  if (props.moduleId && !selectedModule) {
    return <></>;
  } // Eduard: Cheap fix until we refactor Forms

  return (
    <div className="flex flex-col justify-between px-10 py-4">
      <div className="absolute right-1 px-4">
        <IconButton onClick={props.onClose}>
          <Close color="success" sx={{color: 'white'}} />
        </IconButton>
      </div>
      <Typography align="center" variant="h3">
        {props.isEdit ? `Edit '${props.title}' contest template` : 'Create a new contest'}
      </Typography>
      <FormComponent
        button={{
          className: 'mt-25 mb-10',
          label: 'Submit',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        onFirstFieldChange={() => props.dataWasEditedCallback && props.dataWasEditedCallback()}
        inputs={[
          [
            {
              id: 'title',
              error: errors['title'],
              placeholder: 'Contest title*',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a title',
                  },
                ],
              },
              startAdornment: <AutoAwesome />,
              type: 'input',
              value: props.title,
              xs: true,
            },
          ],
          [
            {
              id: 'module-type',
              error: errors['module-type'],
              options: modules.map((module) => ({label: module.module_name, value: module.id})),
              placeholder: 'Module type',
              rules: {},
              type: 'unmanaged-dropdown',
              onChangex: (selected) => {
                const newSelectedModule = modules.find((module) => module.id == selected.value);
                if (newSelectedModule && newSelectedModule.id != selectedModule?.id) {
                  setSelectedModuleToBe(modules.find((module) => module.id == newSelectedModule.id));
                }
              },
              valuex: selectedModule ? {label: selectedModule.module_name, value: selectedModule.id} : null,
              disabled: selectedModule !== undefined,
              disabledTooltip: 'Once selected the module cannot be changed',
            },
            {
              id: 'contest_language',
              error: errors['contest_language'],
              options: languages,
              placeholder: 'Contest language',
              rules: {},
              type: 'unmanaged-dropdown',
              onChangex: (selected) => {
                const newSelectedLanguage = languages.find((language) => language.value == selected.value);
                if (newSelectedLanguage && newSelectedLanguage.value != selectedLanguage?.value) {
                  setSelectedLanguageIdToBe(newSelectedLanguage.value);
                }
              },
              valuex: selectedLanguage,
            },
          ],
          [
            {
              id: 'hard_deadline',
              name: 'hard_deadline',
              className: '',
              error: errors['hard_deadline'],
              rules: {},
              type: 'checkbox',
              subTitle: "Caution: A hard-deadline contest can't be accesesd after the end date (useful for exams)",
              value: props.hard_deadline ?? false,
              title: 'Hard Deadline',
              checkboxText: 'Hard Deadline Contest',
              checkboxChangeCallback: (checked) => setIsHardDeadline(checked),
            },
            {
              type: 'info-text',
              id: 'submission_timeout',
              name: 'submission_timeout',
              title: 'Submission Timeout',
              subTitle:
                'After submitting, participants must wait for the specified amount of time before they can submit again.',
              error: errors['submission_timeout'],
              text: `Submission Rate Limiter: 10s`,
              rules: {},
            },
          ],
          [
            {
              id: 'prize',
              className: 'mt-10',
              error: errors['prize'],
              placeholder: 'Prize',
              rules: {},
              startAdornment: <EmojiEvents />,
              type: 'markdown',
              value: props.prize ?? 'Is there any prize involved?',
            },
          ],
          [
            {
              id: 'rules',
              error: errors['rules'],
              placeholder: 'Rules',
              rules: {},
              startAdornment: <Rule />,
              type: 'markdown',
              value: props.rules ?? 'Fill in any specific rules',
            },
          ],
          [
            {
              id: 'about',
              error: errors['about'],
              placeholder: "What's this contest about?",
              rules: {},
              startAdornment: <Info />,
              type: 'input',
              value: props.about,
            },
          ],
          [
            {
              className: 'mt-10',
              id: 'description',
              error: errors['description'],
              placeholder: 'Describe the contest in a few words*',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a description',
                  },
                ],
              },
              type: 'textarea',
              value: props.description,
            },
          ],
          [
            {
              className: 'mt-25',
              id: 'pairs',
              error: errors['pairs'],
              maxNoEntries: 100,
              rules: {},
              title: 'Problems',
              type: 'problem',
              value: props.pairs,
              selectedLanguageId: selectedLanguage?.value,
              selectedModuleId: (selectedModule && selectedModule.id) ?? undefined,
              disabled: selectedModule === undefined,
              disabledTooltip: "Please first select the contest template's module",
            },
          ],
        ]}
      />
      {selectedLanguageIdToBe && (
        <ConfirmationModal
          message="Changing the contest language will delete all previously assigned problems. Are you sure you want to continue?"
          onClose={() => {
            setSelectedLanguage((prev) => prev);
            setSelectedLanguageIdToBe(null);
          }}
          onAccept={() => {
            setSelectedLanguage({
              label:
                languageOptions.find((language) => language.languageId === selectedLanguageIdToBe)?.label ||
                'All languages',
              value:
                languageOptions.find((language) => language.languageId === selectedLanguageIdToBe)?.languageId || -1,
            });

            setSelectedLanguageIdToBe(null);
          }}
          acceptButton={
            <Button variant="contained" color="warning">
              Accept
            </Button>
          }
        />
      )}
      {selectedModuleToBe && (
        <ConfirmationModal
          message="Setting the contest module will delete all previously assigned problems. Also, once selected, the module cannot EVER be changed. Are you sure you want to continue?"
          onClose={() => {
            setSelectedModule(undefined);
            setSelectedModuleToBe(undefined);
            forceUpdate();
          }}
          onAccept={() => {
            setSelectedModule(selectedModuleToBe);
            setSelectedModuleToBe(undefined);
          }}
          acceptButton={
            <Button variant="contained" color="warning">
              Accept
            </Button>
          }
        />
      )}
    </div>
  );
};
