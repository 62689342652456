import {ReactNode} from 'react';

export class Route {
  key: string;
  path: string;
  element: ReactNode;

  constructor(key: string, path: string, element: ReactNode) {
    this.key = key;
    this.path = path;
    this.element = element;
  }
}
