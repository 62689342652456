export const contestsTableStyles = (theme: any) => ({
  minWidth: 750,
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiTableCell-root': {
    borderColor: theme.palette.card.light,
    color: theme.palette.card.contrastText,
  },
  backgroundColor: theme.palette.card.dark,
  '& .Mui-active': {
    color: `${theme.palette.primary.main} !important`,
  },
  '& .MuiTableSortLabel-icon': {
    color: `${theme.palette.primary.main} !important`,
  },
  '& .MuiTableSortLabel-root:hover': {
    color: `${theme.palette.primary.light} !important`,
  },
});

export const contestsPaginationStyle = (theme: any) => ({
  '& .MuiTablePagination-actions': {
    color: theme.palette.primary.light,
  },
  '& .Mui-disabled': {
    color: `${theme.palette.primary.dark} !important`,
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.light,
  },
  '& .MuiTablePagination-select': {
    color: theme.palette.primary.light,
  },
  '& p': {
    color: theme.palette.primary.light,
  },
});
