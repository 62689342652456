export enum EnrollmentStatus {
  OWNED = 'owned',
  ENROLLED = 'enrolled',
  NOT_ENROLLED = 'not_enrolled',
}

export enum RegistrationStatus {
  FINISHED = 'finished',
  IN_PROGRESS = 'in_progress',
  IN_FUTURE = 'in_future',
}
