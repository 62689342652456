import React from 'react';

import {Position, ShapeProps as Props} from '../types';

export function translate(props: Omit<Props, 'children'>) {
  const values = {
    left: 0,
    paddingLeft: 0,
    paddingTop: 0,
    position: 'static',
    top: 0,
  } as React.CSSProperties;

  /*
   * Relative position
   * => left, top computed automatically accordingly to their parent
   */
  if (props.position === Position.RELATIVE) {
    values.paddingLeft = props.paddingLeft || 0;
    values.paddingTop = props.paddingTop || 0;
    return values;
  }

  /*
   * Absolute position => we should add the padding to the initial position.
   * (setting {position: "absolute"} ignores the properties paddingLeft, paddingTop).
   */
  values.position = 'absolute';
  values.left = props.paddingLeft || 0;
  values.top = props.paddingTop || 0;

  // Extract the window size.
  const {innerWidth: windowWidth, innerHeight: windowHeight} = window;

  // Extract the directions (the pattern is 'vertical_horizontal').
  const directions = props.position.split('-');

  // Vertical position ['top', 'middle', 'bottom']
  switch (directions[0]) {
    case 'middle':
      values.top += windowHeight / 2 - (props.height ?? 0) / 2;
      break;
    case 'bottom':
      values.top += windowHeight - (props.height ?? 0);
      break;
  }

  // Horizontal position ['left', 'middle', 'right']
  switch (directions[1]) {
    case 'center':
      values.left += windowWidth / 2 - props.width / 2;
      break;
    case 'right':
      values.left += windowWidth - props.width;
      break;
  }

  return values;
}
