import {Children} from 'react';

import {Grid} from '@mui/material/';

import {RowProps as Props} from '../../types';
import {filteredStyled} from '../../utils';

const Container = filteredStyled('div', ['fitParentWidth'])((
  props: Omit<Props, 'children' | 'className' | 'spacing'>
) => {
  if (props.fitParentWidth) {
    return {width: '100%'};
  }
  return {};
});

export const Row = ({children, className, fitParentWidth, justifyContent, spacing}: Props) => (
  <Container className={className} fitParentWidth={fitParentWidth}>
    <Grid container alignItems="center" justifyContent={justifyContent} columnSpacing={spacing}>
      {Children.toArray(children)}
    </Grid>
  </Container>
);
