import {TextField} from '@mui/material';
import {useTheme} from '@mui/system';

type DateTimeTextFieldProps = {
  error?: boolean | undefined;
};

export const DateTimeTextField = (InputProps: DateTimeTextFieldProps) => {
  const theme = useTheme();
  return (
    <TextField
      variant="filled"
      sx={{
        backgroundColor: theme.palette.inputBox.backgroundColor,
        svg: {color: 'white'},
        input: {color: 'white'},
      }}
      {...InputProps}
      error={Boolean(InputProps.error)}
    />
  );
};
