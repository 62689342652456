import {Typography} from '@mui/material';

import {InputProps as Props} from '../../../types';
import {Error} from '../error/Error';

export const InfoText = (props: Props) => {
  const error = props.error ?? false;

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col">
        <div className="mt-2 text-lg font-medium">{props.title}</div>
        {props.subTitle && <div className="my-1 text-sm text-gray-400">{props.subTitle}</div>}
      </div>
      <div className="flex flex-row items-center">
        {error !== false && <Error id={props.id} message={error} />}
        <Typography variant="subtitle1">{props.text}</Typography>
      </div>
    </div>
  );
};
