import {useEffect, useReducer, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';
import Select from 'react-select';

import {Button, CircularProgress, Modal, Typography} from '@mui/material';

import {URLs, request, sendGetRequest} from '../../../api';
import {StaffPageOutletProps} from '../StaffPage';
import {ContestTemplatesViewResponse} from '../../../api/responses/ContestsViewResponseTypes';
import {ContestsTemplatesTable} from './ContestTemplatesTable';
import {ModuleType} from '../../../types/entities/ModuleType';
import {NewContestForm} from '../../../components';
import {ContestViewData, ContestViewResponse} from '../../../api/responses/ContestViewResponseTypes';
import {Form} from '../../../components/new-contest/NewContest';
import {Color} from '../../../types';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import AuthorsManagement from '../../../components/authors/AuthorsManagement';
import {ViewAllContestAuthorsResponse} from '../../../api/responses/AuthorsResponseTypes';

export const ContestTemplates = () => {
  const {handleHttpError} = useOutletContext<StaffPageOutletProps>();

  const dropdownStyles = dropdownStylesConfig();
  const navigate = useNavigate();

  // Workaround to not query the server each time we filter
  const [initialTemplates, setInitialTemplates] = useState<ContestTemplatesViewResponse['contests']>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [templates, setTemplates] = useState<ContestTemplatesViewResponse['contests']>([]);
  const [modules, setModules] = useState<Array<{value: number; label: string}>>([]);
  const [selectedTemplateToEdit, setSelectedTemplateToEdit] = useState<ContestViewData | undefined>(undefined);
  const [selectedTemplateIdToAuthorsManage, setSelectedTemplateIdToAuthorsManage] = useState<number | undefined>();
  const [showNewTemplateModal, setShowNewTemplateModal] = useState<boolean>(false);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const [filteredModules, setFilteredModules] = useState<Array<{value: number; label: string}>>([]);

  const [wasDataEdited, setWasDataEdited] = useState<boolean>(false);
  const [closeConfirmation, setCloseConfirmation] = useState<boolean>(false);

  const getContestTemplates = () => {
    sendGetRequest(URLs.getContestTemplates)
      .then((response) => {
        const resonseTemplates: ContestTemplatesViewResponse = response.data;
        setTemplates(resonseTemplates.contests);
        setInitialTemplates(resonseTemplates.contests);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const getModules = () => {
    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modulesResponse: Array<ModuleType> = response.data;
        setModules(modulesResponse.map((module) => ({value: module.id as number, label: module.module_name})));
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getContestTemplates();
    getModules();
  }, [update]);

  const onTemplateEdit = (templateId: number) => {
    sendGetRequest(`${URLs.getContestTemplate(templateId)}`)
      .then((response) => {
        const templateData: ContestViewResponse = response.data.template;
        setSelectedTemplateToEdit(templateData);
        setWasDataEdited(false);
      })
      .catch((err) => {
        if (err) {
          if (err) {
            handleHttpError(err);
          }
        }
      });
  };

  const onTemplateManageAuthors = (templateId: number) => setSelectedTemplateIdToAuthorsManage(templateId);

  const onTemplateView = (templateId: number) => navigate(`/contest/preview/${templateId}/problems`);

  useEffect(() => {
    setTemplates(
      filteredModules.length === 0
        ? initialTemplates
        : initialTemplates.filter((template) =>
            filteredModules.find((filtered) => filtered.value === template.module_id)
          )
    );
  }, [filteredModules]);

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <div className="w-96">
          <Select
            styles={dropdownStyles}
            placeholder="Filter by module"
            isMulti
            isSearchable
            isClearable
            value={filteredModules}
            options={[
              ...modules.filter((module) => ({
                label: filteredModules.find((filtered) => module.value !== filtered.value)?.label,
                value: filteredModules.find((filtered) => module.value !== filtered.value)?.value,
              })),
            ]}
            onChange={(multiValue, _) => {
              const newOptions: Array<{value: number; label: string}> = multiValue as Array<{
                value: number;
                label: string;
              }>;

              setFilteredModules(newOptions);
            }}
          />
        </div>
        <div>
          <Button
            color={Color.PRIMARY}
            variant="contained"
            size="large"
            onClick={() => {
              setShowNewTemplateModal(true);
              setWasDataEdited(false);
            }}
          >
            Create Contest Template
          </Button>
        </div>
      </div>
      {templates.length > 0 && (
        <ContestsTemplatesTable
          modules={(() => {
            const modulesMap: {[key: number]: any} = {};
            modules.forEach((module) => {
              modulesMap[module.value] = module.label;
            });
            return modulesMap;
          })()}
          templates={templates}
          onTemplateEdit={onTemplateEdit}
          onTemplateManageAuthors={onTemplateManageAuthors}
          onTemplateView={onTemplateView}
        />
      )}
      {initialTemplates.length > 0 && templates.length === 0 && !isLoading && (
        <div className="text-center mt-4">
          <Typography variant="h6">No contest templates found based on the selected criteria</Typography>
        </div>
      )}
      {initialTemplates.length === 0 && !isLoading && (
        <div className="text-center mt-4">
          <Typography variant="h6">No contest templates found</Typography>
        </div>
      )}
      {isLoading && (
        <div className="flex items-center justify-center mt-4">
          <CircularProgress color="success" />
        </div>
      )}

      <Modal
        open={selectedTemplateToEdit !== undefined}
        onClose={() => (wasDataEdited ? setCloseConfirmation(true) : setSelectedTemplateToEdit(undefined))}
      >
        <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[80%] h-[90%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          {selectedTemplateToEdit && (
            <NewContestForm
              title={selectedTemplateToEdit.title}
              startDate={selectedTemplateToEdit.start_date}
              endDate={selectedTemplateToEdit.end_date}
              about={selectedTemplateToEdit.about}
              description={selectedTemplateToEdit.description}
              prize={selectedTemplateToEdit.prize}
              rules={selectedTemplateToEdit.rules}
              hard_deadline={selectedTemplateToEdit.hard_deadline}
              pairs={selectedTemplateToEdit.problems.map((problem) => ({
                problem: problem.title,
                quota: 1, // TODO
                languages: problem.languages,
              }))}
              moduleId={selectedTemplateToEdit.module_id}
              language_id={selectedTemplateToEdit.contest_language_id}
              submissionTimeoutDurationSec={selectedTemplateToEdit.submission_timeout}
              isEdit={true}
              url={`${URLs.updateContest}/${selectedTemplateToEdit.id}`}
              method="put"
              onSuccess={() => {
                setSelectedTemplateToEdit(undefined);
                forceUpdate();
              }}
              onClose={() => (wasDataEdited ? setCloseConfirmation(true) : setSelectedTemplateToEdit(undefined))}
              dataWasEditedCallback={() => setWasDataEdited(true)}
            />
          )}
        </div>
      </Modal>
      <Modal
        open={showNewTemplateModal}
        onClose={() => (wasDataEdited ? setCloseConfirmation(true) : setShowNewTemplateModal(false))}
      >
        <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[80%] h-[90%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          <Form
            startDate={new Date()}
            endDate={new Date()}
            url={URLs.createContest}
            method="post"
            onSuccess={() => {
              setShowNewTemplateModal(false);
              forceUpdate();
            }}
            isEdit={false}
            onClose={() => (wasDataEdited ? setCloseConfirmation(true) : setShowNewTemplateModal(false))}
            dataWasEditedCallback={() => setWasDataEdited(true)}
          />
        </div>
      </Modal>

      {selectedTemplateIdToAuthorsManage && (
        <Modal
          open={selectedTemplateIdToAuthorsManage !== undefined}
          onClose={() => setSelectedTemplateIdToAuthorsManage(undefined)}
        >
          <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[60%] h-[70%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <AuthorsManagement
              loadAuthors={async (callback: () => void) => {
                let response: ViewAllContestAuthorsResponse = [];
                await request<{}, {}, ViewAllContestAuthorsResponse>(
                  URLs.getContestAuthors(selectedTemplateIdToAuthorsManage),
                  {
                    method: 'GET',
                    successCallback: (r) => {
                      response = r;
                      callback();
                    },
                    errorCallback: (e: any) => {
                      handleHttpError(e.response.data.error ?? 'There was an error. Please try again later.');
                    },
                  }
                );
                return response;
              }}
              assignAuthor={(authorEmail: string, callback: () => void) => {
                request<{}, {authorEmail: string}, {}>(URLs.assignAuthorToContest(selectedTemplateIdToAuthorsManage), {
                  method: 'POST',
                  body: {authorEmail},
                  successCallback: () => {
                    callback();
                  },
                  errorCallback: (e: any) => {
                    handleHttpError(e.response.data.error ?? 'There was an error. Please try again later.');
                  },
                });
              }}
              unassignAuthor={(authorId: number, callback: () => void) => {
                request<{}, {}, {}>(URLs.unassignAuthorFromContest(selectedTemplateIdToAuthorsManage, authorId), {
                  method: 'DELETE',
                  successCallback: () => {
                    callback();
                  },
                  errorCallback: (e: any) => {
                    handleHttpError(e.response.data.error ?? 'There was an error. Please try again later.');
                  },
                });
              }}
            />
          </div>
        </Modal>
      )}
      {closeConfirmation && (
        <Modal open={closeConfirmation}>
          <div className="flex flex-col items-center gap-3 bg-background-default top-2/4 left-2/4 w-[35%] h-fit px-4 py-5 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <Typography variant="h4">Confirm action</Typography>
            <Typography variant="subtitle2">
              You are about to leave the menu without saving. Any unsaved changes will be lost. Do you want to proceed?
            </Typography>

            <div className="flex flex-row gap-5 mt-2 items-center justify-center">
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  setShowNewTemplateModal(false);
                  setSelectedTemplateToEdit(undefined);
                  setCloseConfirmation(false);
                }}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="gridPrimary"
                onClick={() => {
                  setCloseConfirmation(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
