import {useState} from 'react';

import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import {URLs, sendGetRequest} from '../../api';
import {PutGroupParams} from '../../api/Requests';
import {Group} from '../../types/entities/Group';
import {ModuleType} from '../../types/entities/ModuleType';
import ConfirmationModal from '../modal/ConfirmationModal';
import {fromDate} from '../../utils/date-utils';

type GroupsManagementTableProps = {
  groups: Array<Group>;
  modules: Array<ModuleType>;
  onModifyGroup: (groupId: number, newData: PutGroupParams) => void;
  deleteGroup: (groupId: number) => void;
  handleHttpError: (error: any) => void;
};

function GroupsManagementTable({
  groups,
  modules,
  onModifyGroup,
  deleteGroup,
  handleHttpError,
}: GroupsManagementTableProps) {
  const theme = useTheme();

  const [editInfo, setEditInfo] = useState<{id: number; data: PutGroupParams} | null>(null);
  const [editEnabled, setEditEnabled] = useState(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [groupToDelete, setGroupToDelete] = useState<{groupId: number; isEmpty: boolean} | null>();

  const handleGroupDeletionAction = (groupId: number) => {
    sendGetRequest(URLs.groupProgress(groupId))
      .then((response) => {
        setShowModal(true);
        setGroupToDelete({groupId: groupId, isEmpty: response.status !== 200});
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  return (
    <>
      <Table
        sx={{
          minWidth: 800,
          '& .MuiTableRow-root:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiTableCell-root': {
            borderColor: theme.palette.card.light,
            color: theme.palette.card.contrastText,
          },
          backgroundColor: theme.palette.card.dark,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell> No. </TableCell>
            <TableCell> Name </TableCell>
            <TableCell> Module </TableCell>
            <TableCell> Start Date </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody style={{borderColor: theme.palette.card.dark}}>
          {/* (Eduard): To extract row in a separate component when we decide that we 
                        what to modify more than just the name */}
          {groups &&
            groups.map((group, index) => (
              <TableRow key={group.id}>
                <TableCell> {index + 1} </TableCell>
                <TableCell>
                  {editEnabled && editInfo?.id === group.id ? (
                    <div className="flex flex-row items-center gap-2">
                      <TextField
                        onChange={(event) => {
                          setEditInfo((prev) => {
                            if (prev) {
                              return {id: prev.id || -1, data: {...prev.data, name: event.target.value}};
                            } else {
                              return null;
                            }
                          });
                        }}
                        id="filled-group-name-input"
                        label="Change group name"
                        variant="filled"
                        value={editInfo?.data.name}
                        sx={{
                          width: '70%',
                          backgroundColor: theme.palette.inputBox.backgroundColor,
                          input: {color: 'white'},
                        }}
                      />
                    </div>
                  ) : (
                    group.name
                  )}
                </TableCell>

                <TableCell>{modules.find((module) => group.moduleId === module.id)?.module_name}</TableCell>

                <TableCell> {fromDate(new Date(group.startDate))} </TableCell>

                <TableCell align="center">
                  {editEnabled && editInfo?.id === group.id ? (
                    <div>
                      <IconButton
                        disabled={editInfo.data.name === ''}
                        onClick={() => {
                          onModifyGroup(group.id, editInfo?.data);
                          setEditEnabled(false);
                        }}
                        sx={{color: 'green'}}
                      >
                        <DoneAllIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setEditEnabled(false);
                          setEditInfo(null);
                        }}
                        sx={{color: 'white'}}
                      >
                        <ClearIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setEditEnabled(true);
                        setEditInfo({
                          id: group.id,
                          data: {name: group.name, startDate: group.startDate},
                        });
                      }}
                      sx={{color: 'white'}}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleGroupDeletionAction(group.id)} color="warning">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {groups && groups.length === 0 && (
        <div className="flex justify-center py-3 bg-slate-600">
          <Typography variant="h6">No data to display...</Typography>
        </div>
      )}
      {showModal && groupToDelete && (
        <ConfirmationModal
          message={
            groupToDelete.isEmpty
              ? 'You are about to permanently delete a group with no contests. Are you sure?'
              : 'You are about to permanently delete a group with contests. All the users submissions and rankings will be lost. Are you sure?'
          }
          onClose={() => setShowModal(false)}
          onAccept={() => {
            deleteGroup(groupToDelete.groupId);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}

export default GroupsManagementTable;
