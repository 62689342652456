import {styled, Typography} from '@mui/material';

import {SelectProps} from './Select.props';
import {SingleSelect} from './SingleSelect';
import {MultipleSelect} from './MultipleSelect';

const Container = styled('div')(() => ({
  paddingTop: '10px',
  width: '100%',
}));

export const Select = (props: SelectProps) => (
  <Container>
    <Typography variant="h7">{props.name.toUpperCase()}</Typography>
    <hr />
    {props.type === 'single' ? <SingleSelect {...props} /> : <MultipleSelect {...props} />}
  </Container>
);
