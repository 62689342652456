import {useParams} from 'react-router-dom';

import {Page, ProblemCard} from '../../components';

export const Problem = () => {
  const {problemId, contestId, groupContestId} = useParams();

  if (!problemId) {
    return <></>;
  }

  return (
    <Page>
      <div className="grid grid-cols-12 items-center h-[90vh] w-[90vw]">
        <div className="col-span-12 bg-card-main items-center justify-center rounded-2xl">
          <ProblemCard
            contestId={parseInt(contestId ?? '') ?? -1}
            problemId={Number(problemId)}
            groupContestId={Number(groupContestId)}
          />
        </div>
      </div>
    </Page>
  );
};
