import {useContext} from 'react';
import {useOutletContext} from 'react-router-dom';

import {Typography} from '@mui/material';

import FilterActiveGroupsSelect from '../../components/user/FilterActiveGroupsSelect';
import LeaderboardVisibilityToggle from '../../components/user/LeaderboardVisibilityToggle';
import AppContext from '../../context/AppContext';
import {Role, User} from '../../types';
import {URLs, sendPutRequest} from '../../api';
import {UserSettings} from '../../types/entities/UserSettings';

type ProfileSettingsOutletProps = {
  handleHttpError: any;
};

function ProfileSettings() {
  const context = useContext(AppContext);

  const {handleHttpError}: ProfileSettingsOutletProps = useOutletContext();

  const onSave = async (partialNewUserSettings: UserSettings) => {
    const user = context.user;

    return sendPutRequest(URLs.updateUserSettings(context.user!.id), {settings: partialNewUserSettings})
      .then((response) => {
        user!.settings = response.data.settings;
        context.updateUser(new User(user));

        return true;
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
        return false;
      });
  };

  return (
    <>
      {/* To be added: Some mechanism to focus (auto-scroll to) specific settings, ( example: #auto-groups )
      to be referenced from other pages.*/}
      <Typography variant="h4">Filter active groups</Typography>
      <Typography variant="body1">
        {' '}
        Customize the groups you want to view by applying filters. If no specific groups are selected, all groups will
        be shown by default.
      </Typography>
      <FilterActiveGroupsSelect saveCallback={onSave} handleHttpError={handleHttpError} />
      {(context.user?.role === Role.TEACHER || context.user?.role === Role.CREATOR) && (
        <>
          <Typography variant="h4">Hidden in leaderboard</Typography>
          <Typography variant="body1">
            As a teacher / creator you can choose to appear or not in the leaderboard.
          </Typography>
          <LeaderboardVisibilityToggle saveCallback={onSave} />
        </>
      )}
    </>
  );
}

export default ProfileSettings;
