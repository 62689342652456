import {useMemo} from 'react';

import MDEditor from '@uiw/react-md-editor';
import {Typography} from '@mui/material';

import {Problem} from '../../types';
import {Test} from '../../types/entities/Test';
import {CodeEditor} from '../code-editor/CodeEditor';
import {Category} from '../../types/entities/Category';
import {stringsToColoredChips} from '../problems-table/Chips';

export const Description = (props: Problem) => {
  const example = useMemo(() => props.tests.find((test: Test) => test.id === props.example_id), [props.tests]);

  const calculateHeight = (input: string): number => {
    const lineHeight = 18;
    return Math.min(lineHeight * input.split(/\r\n|\r|\n/).length + lineHeight * 2, 200);
  };

  return (
    <div className="flex flex-col justify-evenly h-full w-full pt-0 pr-8 pb-8 pl-10 gap-2">
      <div className="flex flex-row gap-2 w-full flex-wrap">
        {props.categories && props.categories.length > 0
          ? stringsToColoredChips([...props.categories.map((category: Category) => category.title)])
          : stringsToColoredChips(['Uncategorized'])}
      </div>
      <div className="max-w-full">
        <Typography className="mb-10" variant="h4">
          {props.title}
        </Typography>
      </div>

      <div className="max-w-full">
        {/* <MDEditor
          value={props.description}
          preview="preview"
          style={{
            listStyle: 'disc !important',
          }}
        /> */}
        <MDEditor.Markdown
          source={props.description}
          style={{backgroundColor: 'transparent', listStyle: 'disc !important'}}
        />
      </div>
      {example && (
        <>
          <div>
            <Typography className="mt-25" variant="h7" sx={{fontWeight: 600}}>
              Example:
            </Typography>
          </div>
          <div className="flex flex-col">
            <div>
              <CodeEditor
                className="mb-2"
                height={calculateHeight(example.input)}
                code={example.input}
                readOnly
                title={'Input'}
              />
              <CodeEditor
                className="mb-2"
                height={calculateHeight(example.output)}
                code={example.output}
                readOnly
                title={'Expected output'}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
