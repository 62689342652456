import {FormControl, InputAdornment, OutlinedInput} from '@mui/material';

import {InputProps as Props} from '../../../types/';
import {Error} from '../error/Error';

export const Textarea = (props: Props) => {
  const error = props.error ?? false;
  return (
    <div className={props.className}>
      <FormControl fullWidth={props.fullWidth} variant="outlined">
        <OutlinedInput
          endAdornment={<InputAdornment position="end">{props.endAdornment}</InputAdornment>}
          error={error !== false}
          fullWidth={props.fullWidth}
          id={props.id}
          margin="none"
          maxRows={props.maxRows ?? 6}
          minRows={props.minRows ?? 3}
          multiline={true}
          notched={true}
          onChange={props.onChange}
          placeholder={props.placeholder}
          startAdornment={<InputAdornment position="start">{props.startAdornment}</InputAdornment>}
          value={props.value}
        />
        {error !== false && <Error id={props.id} message={error} />}
      </FormControl>
    </div>
  );
};
