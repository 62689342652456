import {useEffect, useState} from 'react';

import {Grid, Typography, useTheme} from '@mui/material';

import {SwitchProps} from './Switch.props';

export const Switch = (props: SwitchProps) => {
  const [chosen, setChosen] = useState<number>(props.chosen ?? 0);
  const theme = useTheme();

  const commonProps = Object.assign(
    {margin: '10px 0 10px 0', padding: '15px 0 15px 0'},
    props.background ? {backgroundColor: theme.palette.primary.main} : {}
  );

  useEffect(() => {
    if (props.chosen) {
      setChosen(props.chosen);
    }
  }, [props]);

  const updateChosen = (index: number) => {
    setChosen(index);
    if (props.onChange) {
      props.onChange(index);
    }
  };

  const options = props.options.map((option, index) => (
    <Grid key={`tab-$${option.name}-${index}`} item onClick={() => updateChosen(index)} sx={{cursor: 'pointer'}}>
      <Typography className={`${chosen !== index ? 'opacity-50' : ''}`} variant="h6">
        {typeof option.name === 'string' ? option.name.toUpperCase() : option.name}
      </Typography>
    </Grid>
  ));
  const chosenElement = chosen >= 0 ? props.options[chosen].component : <></>;

  if (props.direction === 'horizontal') {
    return (
      <div className="box-border grow">
        <Grid
          container
          alignItems="flex-start"
          columnSpacing={props.spacing ?? 1}
          justifyContent={props.justifyOptions ?? 'space-around'}
          sx={{...commonProps, width: '100%'}}
        >
          {options}
        </Grid>
        {chosenElement}
      </div>
    );
  }

  return (
    <Grid container sx={{boxSizing: 'border-box', minHeight: '100%', padding: '25px', flexGrow: 1}}>
      {props.options.length > 0 && (
        <Grid item xs="auto" sx={{maxHeight: '100%'}}>
          <Grid
            container
            alignItems="space-between"
            direction="column"
            justifyContent="space-between"
            sx={{height: '100%'}}
          >
            {props.header && <Grid item>{props.header}</Grid>}
            <Grid item>
              <Grid
                container
                direction="column"
                sx={{
                  ...commonProps,
                  height: '100%',
                  padding: '35px 25px 25px 25px',
                  textAlign: 'center',
                }}
              >
                {options}
              </Grid>
            </Grid>
            {props.footer && <Grid item>{props.footer}</Grid>}
          </Grid>
        </Grid>
      )}
      <Grid item xs sx={{paddingLeft: '30px'}}>
        {chosenElement}
      </Grid>
    </Grid>
  );
};
