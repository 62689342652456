import {Rule, RuleProps} from './Rule';

type Between = {
  cond: 'between';
  low: number;
  high: number;
};
type Equal = {
  cond: 'equal';
  value: number;
};
type Greater = {
  cond: 'greater';
  value: number;
};
type NotEmpty = {
  cond: 'not-empty';
};
type Smaller = {
  cond: 'smaller';
  value: number;
};

export type LengthProps = RuleProps & (Between | Equal | Greater | NotEmpty | Smaller);

export class Length extends Rule {
  public validate(value: string, props: LengthProps): false | string {
    if (!value) {
      return this.ifError(false, props.message);
    }
    switch (props.cond) {
      case 'between':
        return this.ifError(value.length >= props.low && value.length <= props.high, props.message);
      case 'equal':
        return this.ifError(value.length === props.value, props.message);
      case 'greater':
        return this.ifError(value.length >= props.value, props.message);
      case 'not-empty':
        return this.ifError(value.length > 0, props.message);
      case 'smaller':
        return this.ifError(value.length <= props.value, props.message);
      default:
        return false;
    }
  }
}
