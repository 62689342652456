import {useContext, useEffect, useReducer, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {Modal, Typography, Button as ButtonMUI} from '@mui/material';

import {URLs, sendGetRequest} from '../../api';
import AppContext from '../../context/AppContext';
import {PaginatedProblemResponse, PaginatedProblemsResponse} from '../../api/responses/PaginatedProblemsResponseTypes';
import {Difficulty, Role} from '../../types';
import {FloatingButton, NewProblemForm, Page} from '../../components';
import {ProblemsTableV2} from '../../components/problems-table/ProblemsTableV2';
import ProblemPageFilters from '../../components/problems-table/ProblemsPageFilters';
import {CategoryResponse} from '../../api/responses/CategoriesResponseTypes';
import {Category} from '../../types/entities/Category';
import {ModuleType} from '../../types/entities/ModuleType';

export type ProblemsFilterState = {
  difficulty: Difficulty | null;
  languageId: number | null;
  titleQuery: string;
  category: number | null;
  moduleId: number | null;
  page: number;
  rowsPerPage: number;
};

type FilterAction =
  | {type: 'UPDATE_DIFFICULTY'; payload: Difficulty | null}
  | {type: 'UPDATE_LANGUAGE_ID'; payload: number | null}
  | {type: 'UPDATE_TITLE_QUERY'; payload: string}
  | {type: 'UPDATE_CATEGORY'; payload: number | null}
  | {type: 'UPDATE_MODULE_ID'; payload: number | null}
  | {type: 'UPDATE_PAGE_NO'; payload: number}
  | {type: 'UPDATE_ROWS_PER_PAGE'; payload: number};

const filterReducer = (state: ProblemsFilterState, action: FilterAction): ProblemsFilterState => {
  switch (action.type) {
    case 'UPDATE_DIFFICULTY':
      return {...state, difficulty: action.payload};
    case 'UPDATE_LANGUAGE_ID':
      return {...state, languageId: action.payload};
    case 'UPDATE_TITLE_QUERY':
      return {...state, titleQuery: action.payload};
    case 'UPDATE_CATEGORY':
      return {...state, category: action.payload};
    case 'UPDATE_MODULE_ID':
      return {...state, moduleId: action.payload};
    case 'UPDATE_PAGE_NO':
      return {...state, page: action.payload};
    case 'UPDATE_ROWS_PER_PAGE':
      return {...state, rowsPerPage: action.payload};
    default:
      return {...state};
  }
};

export function ProblemsPageV2() {
  const {dispatchError, user} = useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialState = useRef<ProblemsFilterState>({
    difficulty: (searchParams.get('difficulty') as Difficulty) ?? null,
    languageId: Number(searchParams.get('languageId')) ? Number(searchParams.get('languageId')) : null,
    titleQuery: searchParams.get('titleQuery') ?? '',
    category: Number(searchParams.get('category')) ? Number(searchParams.get('category')) : null,
    moduleId: Number(searchParams.get('moduleId')) ? Number(searchParams.get('moduleId')) : null,
    page: Number(searchParams.get('page')) ? Number(searchParams.get('page')) : 0,
    rowsPerPage: Number(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 10,
  }).current;

  const [problems, setProblems] = useState<Array<PaginatedProblemResponse>>([]);
  const [allCategories, setAllCategories] = useState<Array<Category>>([]);
  const [modules, setModules] = useState<Array<ModuleType>>([]);
  const [noProblems, setNoProblems] = useState<number>(0);
  const [state, dispatch] = useReducer(filterReducer, initialState);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);

  const [wasDataEdited, setWasDataEdited] = useState<boolean>(false);
  const [closeConfirmation, setCloseConfirmation] = useState<boolean>(false);

  const handleDifficultyChange = (value: Difficulty | null) => {
    dispatch({type: 'UPDATE_DIFFICULTY', payload: value});
    handlePageNoChange(0);
  };

  const handleLanguageIdChange = (value: number | null) => {
    dispatch({type: 'UPDATE_LANGUAGE_ID', payload: value});
    handlePageNoChange(0);
  };

  const handleTitleQueryChange = (value: string) => {
    dispatch({type: 'UPDATE_TITLE_QUERY', payload: value});
    handlePageNoChange(0);
  };

  const handleCategoryChange = (value: number | null) => {
    dispatch({type: 'UPDATE_CATEGORY', payload: value});
    handlePageNoChange(0);
  };

  const handleModuleIdChange = (value: number | null) => {
    dispatch({type: 'UPDATE_MODULE_ID', payload: value});
    handlePageNoChange(0);
  };

  const handlePageNoChange = (value: number) => {
    dispatch({type: 'UPDATE_PAGE_NO', payload: value});
  };

  const handleRowsPerPageChange = (value: number) => {
    dispatch({type: 'UPDATE_ROWS_PER_PAGE', payload: value});
    handlePageNoChange(0);
  };

  useEffect(() => {
    const filters: Array<{key: string; value: number | boolean | string}> = [];

    if (state.page) {
      filters.push({key: 'page', value: state.page});
    }
    if (state.rowsPerPage) {
      filters.push({key: 'limit', value: state.rowsPerPage});
    }
    if (state.titleQuery) {
      filters.push({key: 'titleQuery', value: state.titleQuery});
    }
    if (state.category) {
      filters.push({key: 'categoryId', value: state.category});
    }
    if (state.moduleId) {
      filters.push({key: 'moduleId', value: state.moduleId});
    }
    if (state.difficulty) {
      filters.push({key: 'difficulty', value: state.difficulty});
    }
    if (state.languageId) {
      filters.push({key: 'languageId', value: state.languageId});
    }

    const updatedParams = new URLSearchParams();
    filters.forEach((filter) => {
      updatedParams.set(filter.key, filter.value.toString());
    });
    setSearchParams(updatedParams, {replace: true});

    const debounce = setTimeout(() => {
      setIsLoading(true);
      sendGetRequest(URLs.paginatedProblems(filters.map((filter) => `${filter.key}=${filter.value}`).join('&')))
        .then((response) => {
          const data: PaginatedProblemsResponse = response.data;
          setProblems(data.problems);
          setNoProblems(data.metadata.total);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.data && err.response.data.error) {
              dispatchError({errorMessage: err.response.data.error, redirectURL: '/problems'});
            } else {
              dispatchError({errorMessage: 'There was an error. Please try again later'});
            }
          }
        });
    }, 500);
    return () => {
      clearTimeout(debounce);
      setIsLoading(true);
    };
  }, [state]);

  useEffect(() => {
    sendGetRequest(URLs.getAllCategories)
      .then((response) => {
        const categories: Array<CategoryResponse> = response.data;
        setAllCategories(categories as unknown as Array<Category>);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  }, []);

  useEffect(() => {
    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        setModules(modules);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError({errorMessage: err.response.data.error});
          } else {
            dispatchError({errorMessage: 'There was an error. Please try again later'});
          }
        }
      });
  }, []);

  return (
    <>
      <Page>
        {user?.role === Role.ADMIN ||
        user?.role === Role.CREATOR ||
        user?.role === Role.AUTHOR ||
        user?.role === Role.COURSE_ADMIN ||
        user?.role === Role.AUTHOR_TEACHER ? (
          <FloatingButton
            children={[
              <NewProblemForm
                visible="private"
                languages={[]}
                difficulty="easy"
                categories={[]}
                isEdit={false}
                url={URLs.createProblem}
                method="post"
                onClose={() => (wasDataEdited ? setCloseConfirmation(true) : setOpen(false))}
                dataWasEditedCallback={() => setWasDataEdited(true)}
              />,
            ]}
            open={open}
            closeModal={() => (wasDataEdited ? setCloseConfirmation(true) : setOpen(false))}
            openModal={() => {
              setOpen(true);
              setWasDataEdited(false);
            }}
          />
        ) : (
          <></>
        )}
        <div className="flex items-center min-h-full w-full">
          <div className="grid grid-cols-12 items-start gap-4 w-full">
            <div className="col-span-9 bg-card-main rounded-2xl min-h-[730px]">
              <ProblemsTableV2
                problems={problems}
                pageSize={state.rowsPerPage}
                page={state.page}
                setPage={handlePageNoChange}
                setRowsPerPage={handleRowsPerPageChange}
                noProblems={noProblems}
                isLoading={isLoading}
              />
            </div>
            <div className="col-span-3 sticky top-[84px]">
              <div className="flex center justify-between">
                <input
                  className="bg-card-main text-white border-none rounded-3xl outline-none p-4 w-full"
                  placeholder="Search for a problem title"
                  onChange={(e) => handleTitleQueryChange(e.target.value)}
                  defaultValue={state.titleQuery ?? ''}
                />
              </div>
              <ProblemPageFilters
                difficultyDispatch={handleDifficultyChange}
                languageIdDispatch={handleLanguageIdChange}
                categoryDispatch={handleCategoryChange}
                handleModuleIdDispatch={handleModuleIdChange}
                allCategories={allCategories}
                allModules={modules}
                state={state}
              />
            </div>
          </div>
        </div>
      </Page>
      {closeConfirmation && (
        <Modal open={closeConfirmation}>
          <div className="flex flex-col items-center gap-3 bg-background-default top-2/4 left-2/4 w-[35%] h-fit px-4 py-5 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <Typography variant="h4">Confirm action</Typography>
            <Typography variant="subtitle2">
              You are about to leave the menu without saving. Any unsaved changes will be lost. Do you want to proceed?
            </Typography>

            <div className="flex flex-row gap-5 mt-2 items-center justify-center">
              <ButtonMUI
                variant="contained"
                color="warning"
                onClick={() => {
                  setOpen(false);
                  setCloseConfirmation(false);
                }}
              >
                Confirm
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                color="gridPrimary"
                onClick={() => {
                  setCloseConfirmation(false);
                }}
              >
                Cancel
              </ButtonMUI>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
