import {FormControl, InputAdornment, OutlinedInput} from '@mui/material';

import {InputProps as Props} from '../../../types/';
import {Error} from '../error/Error';

export const Input = (props: Props) => {
  const error = props.error ?? false;
  return (
    <div className={props.className}>
      <FormControl fullWidth={props.fullWidth} variant="outlined">
        <OutlinedInput
          endAdornment={<InputAdornment position="end">{props.endAdornment}</InputAdornment>}
          error={error !== false}
          fullWidth={props.fullWidth}
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          placeholder={props.placeholder}
          startAdornment={<InputAdornment position="start">{props.startAdornment}</InputAdornment>}
          type="text"
          value={props.value}
          disabled={props.disabled}
        />
        {error !== false && <Error id={props.id} message={error} />}
      </FormControl>
    </div>
  );
};
