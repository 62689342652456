import {MailOutline, Person} from '@mui/icons-material';
import {styled, Typography} from '@mui/material';

import {Form} from '../../components';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleSubmit = (data: any) => {};

const Container = styled('div')(() => ({
  boxSizing: 'border-box',
  margin: '13% 10% 13% 15%',
  width: '100%',
}));

export const ContactForm = () => (
  <div className="center">
    <Container>
      <Typography variant="h1">Contact</Typography>
      <Form
        button={{
          label: 'Send',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputClass="mb-10"
        inputs={[
          [
            {
              id: 'name',
              placeholder: 'Name',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'Nu ai introdus numele',
                  },
                ],
              },
              startAdornment: <Person />,
              type: 'input',
            },
          ],
          [
            {
              id: 'email',
              placeholder: 'Email',
              rules: {
                pattern: [
                  {
                    type: 'email',
                    message: 'Adresa de email invalida',
                  },
                ],
              },
              startAdornment: <MailOutline />,
              type: 'input',
            },
          ],
          [
            {
              id: 'message',
              placeholder: 'Enter your message here',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'Nu ai introdus mesajul',
                  },
                ],
              },
              type: 'textarea',
            },
          ],
        ]}
      />
    </Container>
  </div>
);
