import {MouseEvent, useState} from 'react';

import {Visibility, VisibilityOff} from '@mui/icons-material';
import {FormControl, IconButton, InputAdornment, OutlinedInput} from '@mui/material';

import {InputProps as Props} from '../../types/';
import {Error} from './error/Error';

const PasswordInput = (props: Omit<Props, 'endAdornment'>) => {
  const [show, setShow] = useState(false);
  const error = props.error ?? false;

  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className={props.className}>
      <FormControl fullWidth={props.fullWidth} variant="outlined">
        <OutlinedInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          error={error !== false}
          fullWidth={props.fullWidth}
          id={props.id}
          onChange={props.onChange}
          placeholder={props.placeholder}
          startAdornment={<InputAdornment position="start">{props.startAdornment}</InputAdornment>}
          type={show ? 'text' : 'password'}
        />
        {error !== false && <Error id={props.id} message={error} />}
      </FormControl>
    </div>
  );
};

export default PasswordInput;
