import {Length, LengthProps} from './Length';
import {Pattern, PatternProps} from './Pattern';

export class Validator {
  private static lengthValidator = new Length();
  private static patternValidator = new Pattern();

  public static validate(value: string, rules: object): false | string {
    let error: false | string = false;

    Object.entries(rules).every(([rule, arrayProps]) => {
      switch (rule) {
        case 'length':
          arrayProps.every((ruleProps: LengthProps) => {
            error = this.lengthValidator.validate(value, ruleProps);
            return error === false;
          });
          break;
        case 'pattern':
          arrayProps.every((ruleProps: PatternProps) => {
            error = this.patternValidator.validate(value, ruleProps);
            return error === false;
          });
          break;
      }
      return error === false;
    });

    return error;
  }
}
