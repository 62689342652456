import {NavLink, Outlet, useOutletContext} from 'react-router-dom';
import {useEffect, useState} from 'react';

import {Badge, Typography} from '@mui/material';

import {URLs, sendGetRequest} from '../../../api';
import {UsersManagementNotificationsResponse} from '../../../api/responses/UsersManagementNotificationsTypes';

export type UsersManagementOutletProps = {
  handleHttpError: (err: any) => void;
};

function UsersManagement() {
  const {handleHttpError}: UsersManagementOutletProps = useOutletContext();

  const [notifications, setNotifications] = useState<{pendingUsers: number}>();

  useEffect(() => {
    sendGetRequest(URLs.getUsersManagementNotifications)
      .then((response) => {
        const data: UsersManagementNotificationsResponse = response.data;

        setNotifications(data);
      })
      .catch((err) => {
        handleHttpError(err);
      });
  }, []);

  const UsersManagementTabs: Array<{name: string; path: string; badgeCount?: number}> = [
    {
      name: 'ACTIVE USERS',
      path: '',
    },
    {
      name: 'PENDING USERS',
      path: 'pending',
      badgeCount: notifications?.pendingUsers ?? 0,
    },
    {
      name: 'ARCHIVED USERS',
      path: 'archived',
    },
  ];

  return (
    <aside className="flex flex-col">
      <nav className="flex gap-8 justify-around">
        {UsersManagementTabs.map((tab) => (
          <NavLink key={tab.name} to={tab.path} replace={true} className="no-underline" end>
            {({isActive}) => (
              <Badge badgeContent={tab.badgeCount ?? null} color="primary">
                <Typography style={{color: isActive ? 'inherit' : '#808080'}} variant="h7">
                  {tab.name}
                </Typography>
              </Badge>
            )}
          </NavLink>
        ))}
      </nav>
      <div className="px-6 py-4 w-full">
        <Outlet context={{handleHttpError}} />
      </div>
    </aside>
  );
}

export default UsersManagement;
