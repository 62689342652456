import {MutableRefObject, useEffect, useState} from 'react';

export function useHeight(ref: MutableRefObject<null>) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    let observerRefValue: Element | null = null;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          setHeight(entry.contentRect.height);
        }
      }
    });
    if (ref.current) {
      resizeObserver.observe(ref.current);
      observerRefValue = ref.current;
    }

    return () => {
      if (observerRefValue) {
        resizeObserver.unobserve(observerRefValue);
      }
    };
  }, [ref]);

  return height;
}

export function useWidth(ref: MutableRefObject<null>) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    let observerRefValue: Element | null = null;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          setWidth(entry.contentRect.width);
        }
      }
    });
    if (ref.current) {
      resizeObserver.observe(ref.current);
      observerRefValue = ref.current;
    }

    return () => {
      if (observerRefValue) {
        resizeObserver.unobserve(observerRefValue);
      }
    };
  }, [ref]);

  return width;
}
