import {useContext, useEffect, useState} from 'react';

import {CircularProgress, Typography} from '@mui/material';

import {AuthorEntry} from '../../../api/responses/AuthorsResponseTypes';
import AuthorsManagementTable from './tables/AuthorsManagementTable';
import {request, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';
import AuthorModulesManagement from './modals/AuthorModulesManagement';
import AuthorProblemsManagement from './modals/AuthorProblemsManagement';
import AuthorContestsManagement from './modals/AuthorContestsManagement';

type MemberEntry = {
  memberId: number;
  firstName: string;
  lastName: string;
  email: string;
};

function AuthorsManagement() {
  const {dispatchError} = useContext(AppContext);
  const [memberType, setMemberType] = useState<'author' | 'course-admin' | 'teacher-author'>('author');
  const [authors, setAuthors] = useState<Array<AuthorEntry>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [viewModulesMemberId, setViewModulesMemberId] = useState<number | null>(null);
  const [viewProblemsMemberId, setViewProblemsMemberId] = useState<number | null>(null);
  const [viewContestsMemberId, setViewContestsMemberId] = useState<number | null>(null);

  useEffect(() => {
    setIsLoading(true);
    let url = '';

    switch (memberType) {
      case 'author':
        url = URLs.getAllAuthors;
        break;
      case 'course-admin':
        url = URLs.getAllCourseAdmins;
        break;
      case 'teacher-author':
        url = URLs.getAllTeacherAuthors;
        break;
      default:
        dispatchError({errorMessage: 'There was an error. Please try again later.', redirectURL: '/staff/users'});
    }

    request<{}, {}, Array<MemberEntry>>(url, {
      method: 'GET',
      successCallback: (response) => {
        setAuthors(response.map((m) => ({authorId: m.memberId, ...m})));
        setIsLoading(false);
      },
      errorCallback: () => {
        dispatchError({errorMessage: 'There was an error. Please try again later.', redirectURL: '/staff/users'});
      },
    });
  }, [memberType]);

  return (
    <div className="flex flex-col gap-2">
      <nav className="flex gap-8 justify-around">
        {UsersManagementTabs.map((tab) => (
          <Typography
            className="cursor-pointer"
            onClick={() => setMemberType(tab.id)}
            key={tab.name}
            style={{color: memberType == tab.id ? 'inherit' : '#808080'}}
            variant="h7"
          >
            {tab.name}
          </Typography>
        ))}
      </nav>
      {!isLoading && (
        <AuthorsManagementTable
          viewModulesCallback={(memberId) => setViewModulesMemberId(memberId)}
          viewProblemsCallback={(memberId) => setViewProblemsMemberId(memberId)}
          viewContestsCallback={(memberId) => setViewContestsMemberId(memberId)}
          authors={authors}
          variant={memberType}
        />
      )}
      {viewModulesMemberId && authors.find((a) => a.authorId == viewModulesMemberId) && (
        <AuthorModulesManagement
          isOpen={viewModulesMemberId != null}
          onClose={() => setViewModulesMemberId(null)}
          author={authors.find((a) => a.authorId == viewModulesMemberId)!}
        />
      )}
      {viewProblemsMemberId && authors.find((a) => a.authorId == viewProblemsMemberId) && (
        <AuthorProblemsManagement
          isOpen={viewProblemsMemberId != null}
          onClose={() => setViewProblemsMemberId(null)}
          author={authors.find((a) => a.authorId == viewProblemsMemberId)!}
        />
      )}
      {viewContestsMemberId && authors.find((a) => a.authorId == viewContestsMemberId) && (
        <AuthorContestsManagement
          isOpen={viewContestsMemberId != null}
          onClose={() => setViewContestsMemberId(null)}
          author={authors.find((a) => a.authorId == viewContestsMemberId)!}
        />
      )}

      {isLoading && (
        <div className="flex flex-row justify-center items-center content-center">
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
}

export default AuthorsManagement;

const UsersManagementTabs: Array<{name: string; id: 'author' | 'course-admin' | 'teacher-author'}> = [
  {
    name: 'AUTHORS',
    id: 'author',
  },
  {
    name: 'COURSE ADMINS',
    id: 'course-admin',
  },
  {
    name: 'TEACHER AUTHORS',
    id: 'teacher-author',
  },
];
