import {useEffect, useState} from 'react';

import {
  Button,
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Tooltip,
  useTheme,
} from '@mui/material';
import {KeyboardArrowRight} from '@mui/icons-material';

import {InputProps} from '../../../types';
import ConfirmationModal from '../../modal/ConfirmationModal';

function not(a: Array<{id: number; preSelected: boolean}>, b: Array<{id: number; preSelected: boolean}>) {
  return a.filter((av) => !b.some((bv) => av.id === bv.id));
}

function intersection(a: Array<{id: number; preSelected: boolean}>, b: Array<{id: number; preSelected: boolean}>) {
  return a.filter((av) => b.some((bv) => av.id === bv.id));
}

function TransferList({
  removeOldForbidden,
  valuex,
  allAvailableItems,
  onChangex,
  placeholder,
  promptMessage,
  itemName,
}: InputProps) {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPropmpted, setIsPrompted] = useState<boolean>(false);
  const [wasPrompted, setWasPrompted] = useState<boolean>(false);

  const [selectedItems, _] = useState<Array<number>>(valuex ?? []);

  const [checked, setChecked] = useState<Array<{id: number; preSelected: boolean}>>([]);
  const [left, setLeft] = useState<Array<{id: number; preSelected: boolean}>>([
    ...allAvailableItems!
      .map((am) => ({id: am.value, preSelected: false}))
      .filter((am) => !selectedItems.includes(am.id)),
  ]);
  const [right, setRight] = useState<Array<{id: number; preSelected: boolean}>>([
    ...selectedItems.map((sm) => ({id: sm, preSelected: true})),
  ]);

  useEffect(() => {
    if (onChangex) {
      onChangex(right.map((i) => i.id));
    }
  }, [right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const getItemName = (plural: boolean) => (itemName ? itemName + (plural ? 's' : '') : 'item' + (plural ? 's' : ''));

  const handleToggle = (value: {id: number; preSelected: boolean}) => () => {
    if (!(removeOldForbidden && value.preSelected)) {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (items: Array<{id: number; preSelected: boolean}>) => (
    <Paper
      sx={{
        width: 250,
        height: 290,
        overflow: 'auto',
        backgroundColor: theme.palette.card.main,
      }}
    >
      <List dense component="div" role="list">
        {items.map((value: {id: number; preSelected: boolean}) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <Tooltip
              key={value.id}
              placement="right"
              title={value.preSelected && removeOldForbidden ? `Cannot unassign old ${getItemName(true)}` : ''}
              arrow
            >
              <ListItemButton role="listitem" onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    disabled={value.preSelected}
                    checked={checked.includes(value)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={allAvailableItems!.find((am) => am.value == value.id)?.label} />
              </ListItemButton>
            </Tooltip>
          );
        })}
        {items.length === 0 && (
          <div className="text-gray-200 text-center  text-sm">{`There are no ${getItemName(true)} left...`}</div>
        )}
      </List>
    </Paper>
  );

  if (!allAvailableItems) {
    return <></>;
  }

  return (
    <>
      <button
        type="button"
        onClick={() => {
          if (!wasPrompted && promptMessage) {
            setIsPrompted(true);
          } else {
            setIsOpen(true);
          }
        }}
        className="flex flex-row w-full h-full bg-[#384D58] px-3 rounded-md justify-between items-center"
      >
        <span>{placeholder ?? 'Select items...'}</span>
        <KeyboardArrowRight />
      </button>

      {isOpen && (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-fit h-fit min-h-[45%] p-8 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <div className="flex flex-row items-center justify-center gap-3">
              <div className="flex flex-col items-center gap-1">
                <span>{`${getItemName(false).toLocaleUpperCase()}`} OPTIONS</span>
                {customList(left)}
              </div>

              <div className="flex flex-col gap-3">
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    left.filter((r) => r.preSelected === false).length === 0
                      ? "There aren't any items left to add"
                      : leftChecked.length === 0
                      ? 'Select an item from the list on the left first'
                      : ''
                  }
                >
                  <div>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      Add &gt;
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  arrow
                  title={
                    right.filter((r) => r.preSelected === false).length === 0
                      ? "There aren't any items left to remove"
                      : rightChecked.length === 0
                      ? 'Select an item from the list on the right first'
                      : ''
                  }
                >
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth={true}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt; Remove
                    </Button>
                  </div>
                </Tooltip>
              </div>
              <div className="flex flex-col items-center gap-1">
                <span>SELECTED {getItemName(true).toLocaleUpperCase()}</span>
                {customList(right)}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isPropmpted && promptMessage && (
        <ConfirmationModal
          message={promptMessage}
          onClose={() => {
            setIsPrompted(false);
            setIsOpen(false);
          }}
          onAccept={() => {
            setIsPrompted(false);
            setWasPrompted(true);
            setIsOpen(true);
          }}
          acceptButton={
            <Button variant="contained" color="warning">
              Accept
            </Button>
          }
        />
      )}
    </>
  );
}

export default TransferList;
