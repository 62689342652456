import {useState} from 'react';

import {MailOutline} from '@mui/icons-material';
import {styled, Typography} from '@mui/material';

import {Form, RedirectModal} from '../../../components';
import {breakpoints} from '../../../types/grid/Breakpoints';
import {sendPostRequest, URLs} from '../../../api';
import {Color} from '../../../types';

const Container = styled('div')(() => ({
  boxSizing: 'border-box',
  padding: '7% 10% 7% 10%',
  textAlign: 'center',
  width: '100%',

  [breakpoints.up_to_md.css]: {
    padding: '10%',
  },
}));

export const ResetPasswordForm = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = (data: any) => {
    sendPostRequest(URLs.resetPassword1, {...data})
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            setError(err.response.data.error);
          } else {
            setError('There was an error. Please try again later');
          }
        }
      });
  };

  return (
    <Container>
      {(error || success) && (
        <RedirectModal
          buttonColor={Color.PRIMARY}
          buttonLabel="Go back"
          children={[
            <Typography variant="h2">{success ? 'Email sent' : 'ERROR'}</Typography>,
            <Typography variant="h4">
              {success ? 'Please check your email address and follow the required steps' : error}
            </Typography>,
          ]}
          redirectUrl={success ? '/' : '/reset-password'}
        />
      )}
      <Typography sx={{paddingBottom: '20px'}} variant="h3">
        Forgot your password?
      </Typography>
      <Typography sx={{paddingBottom: '10px'}} variant="h5">
        Enter your email address and you will receive a link to reset your password.
      </Typography>
      <hr />
      <Form
        button={{
          className: 'mt-10 mb-10',
          label: 'Submit',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputClass="mb-10"
        inputs={[
          [
            {
              id: 'email',
              error: false,
              placeholder: 'Email',
              rules: {},
              startAdornment: <MailOutline />,
              type: 'input',
            },
          ],
        ]}
      />
    </Container>
  );
};
