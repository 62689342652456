class Breakpoint {
  css: string;
  maxWidth?: number;
  minWidth?: number;
  name: string;

  constructor(name: string, minWidth?: number, maxWidth?: number) {
    this.css = '@media screen';
    if (minWidth) {
      this.css += ` and (min-width: ${minWidth}px)`;
    }
    if (maxWidth) {
      this.css += ` and (max-width: ${maxWidth}px)`;
    }
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.name = name;
  }
}

export const breakpoints: {[key: string]: Breakpoint} = {
  xs: new Breakpoint('xs', 0, 600),
  sm: new Breakpoint('sm', 600, 900),
  md: new Breakpoint('md', 900, 1200),
  lg: new Breakpoint('lg', 1200, 1536),
  xl: new Breakpoint('xl', 1536),

  from_xs: new Breakpoint('xs', 0),
  from_sm: new Breakpoint('sm', 600),
  from_md: new Breakpoint('lg', 900),
  from_lg: new Breakpoint('lg', 1200),
  from_xl: new Breakpoint('xl', 1536),

  up_to_sm: new Breakpoint('sm', undefined, 600),
  up_to_md: new Breakpoint('md', undefined, 900),
  up_to_lg: new Breakpoint('lg', undefined, 1200),
  up_to_xl: new Breakpoint('xl', undefined, 1536),
};
