import {Role} from '../../../../types';

export type ActiveUsersArrangementsState = {
  nameQuery: string | null;
  emailQuery: string | null;
  role: Role | null;
  groupId: number | null;
  page: number;
  rowsPerPage: number;
};

export type SetArrangementAction =
  | {type: 'UPDATE_NAME_QUERY'; payload: string | null}
  | {type: 'UPDATE_EMAIL_QUERY'; payload: string | null}
  | {type: 'UPDATE_ROLE'; payload: Role | null}
  | {type: 'UPDATE_GROUP'; payload: number | null}
  | {type: 'UPDATE_PAGE_NO'; payload: number}
  | {type: 'UPDATE_ROWS_PER_PAGE'; payload: number};

export const filterReducer = (
  state: ActiveUsersArrangementsState,
  action: SetArrangementAction
): ActiveUsersArrangementsState => {
  switch (action.type) {
    case 'UPDATE_NAME_QUERY':
      return {...state, nameQuery: action.payload};
    case 'UPDATE_EMAIL_QUERY':
      return {...state, emailQuery: action.payload};
    case 'UPDATE_ROLE':
      return {...state, role: action.payload};
    case 'UPDATE_GROUP':
      return {...state, groupId: action.payload};
    case 'UPDATE_PAGE_NO':
      return {...state, page: action.payload};
    case 'UPDATE_ROWS_PER_PAGE':
      return {...state, rowsPerPage: action.payload};
    default:
      return {...state};
  }
};

export type UpdateUserParams = {
  id: number;
  firstName: string;
  lastName: string;
  nickName: string;
  email: string;
  role: number;
  groupsIds: Array<number>;
};
