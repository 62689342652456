import {styled} from '@mui/material/styles';
import {Pagination as MuiPagination, PaginationProps} from '@mui/material';

const CenteredPagination = styled(MuiPagination)(() => ({
  margin: 'auto',
  paddingBottom: '10px',
  width: '100%',

  ul: {
    justifyContent: 'center',

    'list-style-type': 'none',

    '*': {
      color: 'white !important',
    },
  },

  li: {
    'list-style-type': 'none',

    '&::marker': {
      content: '""',
    },
  },
}));

export const Pagination = (props: PaginationProps) => <CenteredPagination {...props} />;
