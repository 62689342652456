import {useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';

import {Button} from '@mui/material';

import {sendGetRequest} from '../../api';

type ProblemType = {
  problem_name: string;
  points: number;
};

type CSVType = {
  user: string;
  points: string;
  problems: Array<ProblemType>;
};

export const ExportData = () => {
  const contest_id = useParams();
  const [data, setData] = useState([]);
  const [problems, setProblems] = useState<string[]>([]);

  const exportData = () => {
    const csvRows = data.map((item) => {
      const typedItem = item as CSVType;
      const problemPoints = typedItem.problems.map((problem) => problem.points.toString());
      const problemNames = typedItem.problems.map((problem) => problem.problem_name);
      setProblems(problemNames);
      const row = [typedItem.user, typedItem.points, ...problemPoints];
      return row;
    });

    const headers = ['Username', 'Grade', ...problems];
    csvRows.unshift(headers);

    const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.map((e) => e.join(',')).join('\n');

    const link = document.createElement('a');
    link.href = csvContent;
    link.download = 'contest-results.csv';

    link.click();
  };

  useEffect(() => {
    sendGetRequest(`/contests/${contest_id.id}/export`).then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <Button onClick={exportData} variant="contained" size="small">
      Export Contest Data
    </Button>
  );
};
