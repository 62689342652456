import {Children} from 'react';

import {Email, PinDrop} from '@mui/icons-material';
import {Grid, styled, Typography} from '@mui/material';

import {Row} from '../../components';

const Container = styled('div')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  padding: '15% 15% 15% 10%',
  width: '100%',
}));

const rows = [
  {
    icon: <Email color="secondary" fontSize="large" />,
    text: 'office@devmind.ro',
  },
  {
    icon: <PinDrop color="secondary" fontSize="large" />,
    text: 'Cotroceni Business Center, Sector 6, București',
  },
];

export const ContactDetails = () => (
  <div className="center">
    <Container>
      <Typography variant="h1">Reach us</Typography>
      <div>
        {Children.toArray(
          rows.map(({icon, text}) => (
            <Row className="mt-25" fitParentWidth={true} justifyContent="flex-start" spacing={2}>
              {Children.toArray([
                <Grid item>{icon}</Grid>,
                <Grid item sx={{maxWidth: '85%'}}>
                  <Typography variant="h5">{text}</Typography>
                </Grid>,
              ])}
            </Row>
          ))
        )}
      </div>
    </Container>
  </div>
);
