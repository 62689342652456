import React, {ReactElement, useContext, useRef} from 'react';

import AppContext from '../../context/AppContext';
import {useWidth} from '../../hooks/Size';
import {PageProps} from '../../types';
import {Menu} from '../menu/Menu';

function toArray(children?: ReactElement | Array<ReactElement>) {
  if (!children) {
    return [];
  }
  return Array.isArray(children) ? children : [children];
}

/**
 * Home page
 */
export const Page = (props: PageProps) => {
  const ref = useRef(null);
  const width = useWidth(ref);
  const context = useContext(AppContext);

  return (
    <div className="flex flex-col align-middle min-h-full min-w-full m-0 items-center px-16 gap-2" ref={ref}>
      <div className="sticky top-0 w-full z-50">
        <Menu role={context.user !== undefined ? context.user.role : undefined} width={width} />
      </div>
      {toArray(props.children).map((component, index) =>
        React.cloneElement(component, {
          key: index,
          parentwidth: width,
        })
      )}
    </div>
  );
};
