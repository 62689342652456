import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import {AuthorsManagementTableHead} from './AuthorsManagementTableHead';
import {contestsPaginationStyle, contestsTableStyles} from '../../../contests/contests-table/contest-table-styles';
import {getComparator, stableSort} from '../../../../utils/tables/TableSorting';
import {AllAuthorsResponse, AuthorEntry} from '../../../../api/responses/AuthorsResponseTypes';

export type Order = 'asc' | 'desc';
export interface Data {
  index: number;
  fullname: string;
  email: string;
  actions: any;
}

export type AuthorsTableProps = {
  authors: AllAuthorsResponse;
  viewModulesCallback: (authorId: number) => void;
  viewProblemsCallback: (authorId: number) => void;
  viewContestsCallback: (authorId: number) => void;
  variant: 'author' | 'course-admin' | 'teacher-author';
};

function AuthorsManagementTable({
  authors,
  viewModulesCallback,
  viewProblemsCallback,
  viewContestsCallback,
  variant,
}: AuthorsTableProps) {
  const params = new URLSearchParams(location.search);

  const [order, setOrder] = useState<Order>((params.get('order') as Order) || 'desc');
  const [orderBy, setOrderBy] = useState<keyof Data>((params.get('orderby') as keyof Data) || 'start_date');
  const [page, setPage] = useState(0);
  const [dense, _setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const theme = useTheme();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    navigate(`/staff/authors?orderby=${property}&order=${isAsc ? 'desc' : 'asc'}`, {replace: true});
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - authors.length) : 0;

  useEffect(() => {
    setPage(0);
  }, []);

  return (
    <div className="w-full py-2">
      <TableContainer component={Paper}>
        <Table sx={contestsTableStyles(theme)} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <AuthorsManagementTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={authors.length}
          />
          <TableBody>
            {stableSort(authors, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((author: AuthorEntry, index) => (
                <TableRow hover tabIndex={-1} key={author.authorId} id={`template-row-${index}`}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{`${author.firstName} ${author.lastName}`}</TableCell>
                  <TableCell>{author.email}</TableCell>
                  <TableCell>
                    <div className="flex flex-col gap-3">
                      <Button size="small" onClick={() => viewModulesCallback(author.authorId)} variant="contained">
                        Modules
                      </Button>
                      {variant !== 'course-admin' && (
                        <>
                          <Button
                            size="small"
                            onClick={() => viewProblemsCallback(author.authorId)}
                            variant="contained"
                          >
                            Problems
                          </Button>
                          <Button
                            size="small"
                            onClick={() => viewContestsCallback(author.authorId)}
                            variant="contained"
                          >
                            Contest Templates
                          </Button>
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 43) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {authors.length === 0 && (
          <div className="flex flex-col justify-center items-center py-4 gap-2 bg-card-dark">
            <Typography variant="h5">There are no authors meeting the criteria.</Typography>
          </div>
        )}
      </TableContainer>
      <TablePagination
        sx={contestsPaginationStyle(theme)}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={authors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
export default AuthorsManagementTable;
