import {useMemo, useState} from 'react';

import {IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

import {Group} from '../../types/entities/Group';
import {Role, User} from '../../types';
import {rolesMapping} from '../../utils/index';
import {getComparator, stableSort} from '../../utils/tables/TableSorting';
import {GroupUsersEnhancedTableHead} from './GroupUsersTableHeader';

type GroupUsersTableProps = {
  users: Array<User>;
  group: Group;
  userUnassignCallback: (groupId: number, userEmail: string) => void;
};

export interface Data {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: number;
  action: any;
}

export type Order = 'asc' | 'desc';

function GroupUsersTable({users, group, userUnassignCallback}: GroupUsersTableProps) {
  const DEFAULT_AUTHORIZED_USER_INDEX = 0;

  const theme = useTheme();

  const authorizedUsers = useMemo<User[]>(
    () =>
      users.filter(
        (user) =>
          rolesMapping(Number.parseInt(user.role)) !== Role.STUDENT &&
          rolesMapping(Number.parseInt(user.role)) !== Role.GUEST
      ) ?? [],
    [users]
  );
  const students = useMemo<User[]>(
    () =>
      users.filter(
        (user) =>
          rolesMapping(Number.parseInt(user.role)) === Role.STUDENT ||
          rolesMapping(Number.parseInt(user.role)) === Role.GUEST
      ) ?? [],
    [users]
  );

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('firstName');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className="w-full py-2">
      <TableContainer>
        <Table
          sx={{
            minWidth: 800,
            '& .MuiTableRow-root:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTableCell-root': {
              borderColor: theme.palette.card.light,
              color: theme.palette.card.contrastText,
            },
            '& .Mui-active': {
              color: `${theme.palette.primary.main} !important`,
            },
            '& .MuiTableSortLabel-root:hover': {
              color: `${theme.palette.primary.light} !important`,
            },
            '& .MuiTableSortLabel-icon': {
              color: `${theme.palette.primary.main} !important`,
            },
            backgroundColor: theme.palette.card.dark,
          }}
          aria-label="simple table"
        >
          <GroupUsersEnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={users.length}
          />
          <TableBody style={{borderColor: theme.palette.card.dark}}>
            {authorizedUsers &&
              stableSort(authorizedUsers, getComparator(order, orderBy)).map((user: User) => {
                const role: Role = rolesMapping(Number.parseInt(user.role));
                const userTitle = role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();

                return (
                  <TableRow
                    sx={{
                      '&:last-child td': {
                        borderBottom: 3,
                        borderColor: theme.palette.card.light,
                      },
                    }}
                    key={user.id}
                  >
                    <TableCell> {DEFAULT_AUTHORIZED_USER_INDEX} </TableCell>
                    <TableCell> {user.firstName} </TableCell>
                    <TableCell> {user.lastName} </TableCell>
                    <TableCell> {user.email} </TableCell>
                    <TableCell> {userTitle} </TableCell>

                    <TableCell>
                      <IconButton onClick={() => userUnassignCallback(group.id, user.email)} sx={{color: 'white'}}>
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableBody>
            {stableSort(students, getComparator(order, orderBy)).map((user: User, index: number) => {
              const role: Role = rolesMapping(Number.parseInt(user.role));
              const userTitle = role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();

              return (
                <TableRow key={user.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell> {user.firstName} </TableCell>
                  <TableCell> {user.lastName} </TableCell>
                  <TableCell> {user.email} </TableCell>
                  <TableCell> {userTitle} </TableCell>
                  <TableCell>
                    <IconButton onClick={() => userUnassignCallback(group.id, user.email)} sx={{color: 'white'}}>
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {users.length === 0 && (
          <div className="flex justify-center items-center py-4 bg-card-dark">
            <Typography variant="h5">No data to be displayed...</Typography>
          </div>
        )}
      </TableContainer>
    </div>
  );
}

export default GroupUsersTable;
