import React from 'react';

import {Modal as MuiModal} from '@mui/material';

import {ModalWithButtonProps as Props} from './Modal.props';
import {Button} from '../..';
import {ButtonSize, Color} from '../../../types';

export const ModalWithButton = (props: Props) => {
  const makeButton = () => {
    if (props.button.element) {
      return React.cloneElement(props.button.element, {
        className: 'mt-10',
        onClick: props.openModal,
      });
    }
    return (
      <Button
        className="mt-10"
        color={props.button.color ?? Color.PRIMARY}
        label={props.button.label!}
        onClick={props.openModal}
        variant={props.button.variant}
        size={ButtonSize.LARGE}
      />
    );
  };

  return (
    <>
      {makeButton()}
      <MuiModal open={props.open} onClose={props.closeModal}>
        <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[80%] h-[90%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          {props.children}
        </div>
      </MuiModal>
    </>
  );
};
