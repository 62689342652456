import {ReactNode, useContext, useState} from 'react';
import {useNavigate} from 'react-router';

import {IconButton, Modal} from '@mui/material';
import {Close} from '@mui/icons-material';

import AppContext from '../../../context/AppContext';
import {ButtonSize, Color} from '../../../types';
import {Button} from '../../';

export type RedirectModalProps = {
  buttonLabel: string;
  buttonColor: Color;
  children?: Array<ReactNode>;
  redirectUrl?: string;
  hasCloseIconButton?: boolean;
};

export const RedirectModal = (props: RedirectModalProps) => {
  const context = useContext(AppContext);

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Modal open={open}>
      <div className="bg-background-default rounded-xl h-fit w-[500px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 outline-none">
        {props.hasCloseIconButton && (
          <div className="absolute right-1 py-3 px-2">
            <IconButton
              onClick={() => {
                setOpen(false);
                props.redirectUrl && navigate(props.redirectUrl);
                context.clearError();
              }}
            >
              <Close color="success" sx={{color: 'white'}} />
            </IconButton>
          </div>
        )}
        <div className="flex flex-col justify-between items-center gap-6 h-fit w-full p-6">
          {props.children ? [...props.children] : []}
          <Button
            label={props.buttonLabel}
            color={props.buttonColor}
            size={ButtonSize.LARGE}
            onClick={() => {
              setOpen(false);
              props.redirectUrl && navigate(props.redirectUrl);
              context.clearError();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
